import React, { useEffect } from 'react'
import { object } from 'prop-types'
import { checkDomain, matchDomains } from '../core/domain/domain'

const useField = (props) => {
  const [invisible, setInvisible] = React.useState(true)
  const [required, setRequired] = React.useState(false)
  const [readonly, setReadOnly] = React.useState(false)
  const { invisible: inv, required: req, readonly: rea, onchangeData , rootField, index, name } = props
  const nameField = rootField ? `${rootField?.name}.${index}.${name}` : null
  useEffect(() => {
    if (onchangeData && Object.keys(onchangeData).length > 0) {
      setRequired(typeof req === object ? matchDomains(onchangeData, req) : checkDomain(onchangeData, req))
      setInvisible(matchDomains(onchangeData, inv))
      setReadOnly(typeof rea === object ? matchDomains(onchangeData, rea) : checkDomain(onchangeData, rea))
    }
  }, [onchangeData])

  return {
    invisible,
    required,
    readonly,
    nameField,
  }
}

export default useField
