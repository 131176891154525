import { create } from 'zustand'

const useSidebar = create((set) => ({
  isToggle: false,
  menuList: {},
  isNearLogin: false,
  setToggle: () => set((state) => ({ isToggle: !state.isToggle })),
  setIsToggle: (newIsToggle) => set(() => ({ isToggle: newIsToggle })),
  setMenuList: (newMenuList) => set(() => ({ menuList: newMenuList })),
  setIsNearLogin: (newNearLogin) => set(() => ({ isNearLogin: newNearLogin })),
}))

export default useSidebar
