import React from 'react';
import { Controller } from 'react-hook-form';
import { convertFloatToTime, convertTimeToFloat, isFloat, isNumber } from '../../util/util';

const FloatTime = ({ name, control, defaultValue = 0, isForm, value }) => {
    if (!isForm) {
        const formatValue = value ?? 0
        return <span>{convertFloatToTime(formatValue)}</span>
    }

    return (
        <div className="relative field !min-w-[150px]">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field: { onChange, onBlur, value } }) => {
                    const handleInputChange = (e) => {
                        const input = e.target.value;
                        onChange(convertTimeToFloat(input));
                    };

                    const handleBlur = () => {
                        if (isFloat(value) || isNumber(value)) {
                            const floatVal = parseFloat(value);
                            const timeString = convertFloatToTime(floatVal);
                            onChange(floatVal);
                        }
                    };

                    return (
                        <input
                            type="time"
                            value={convertFloatToTime(value)}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            className="absolute inset-0 w-fit bg-transparent pl-2"
                            step={1}
                        />
                    );
                }}
            />
        </div>
    );
};

export default FloatTime;
