import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import ViewServices from '../../core/services/view-service/view-service'
import useFormStore from '../../store/form'
import useHeaderStore from '../../store/header'
import Form from './form'
import Chatter from '../chatter/page'

const FormView = () => {
  const [searchParams] = useSearchParams()
  const resModel = searchParams.get('model')
  const id = parseInt(searchParams.get('id'))
  const vid = parseInt(searchParams.get('vid'))
  const { lang } = useHeaderStore()

  const { setViewDataStore } = useFormStore()
  const { rootContext } = useHeaderStore()
  const formSubmitRef = useRef(null)

  // fetch action data
  const { data: actionReponse } = useQuery({
    queryKey: [`action-${resModel}-${vid}-${lang}`, vid, id, lang],
    queryFn: () => ViewServices.getViewById({ id: vid, lang: lang }),
    enabled: !!vid,
    refetchOnWindowFocus: false,
  })
  const actionData = actionReponse?.[0] ?? JSON.parse(sessionStorage.getItem("actionData"))

  const { data: viewResponse, isLoading, refetch } = useQuery({
    queryKey: [`view-${resModel}`, actionData?.id, lang],
    queryFn: () => ViewServices.getFieldView({
      resModel: actionData?.res_model,
      views: [...actionData?.views, [Array.isArray(actionData?.search_view_id) ? actionData?.search_view_id[0] : actionData?.search_view_id, 'search']],
      context: rootContext,
      id: isNaN(vid) ? actionData?.id : vid
    }),
    enabled: !!actionData,
    refetchOnWindowFocus: false,
  })

  const viewData = viewResponse ?? JSON.parse(sessionStorage.getItem("viewData"))

  const storeData = async () => {
    try {
      if (actionReponse && actionReponse.length) {
        sessionStorage.setItem('actionData', JSON.stringify(actionReponse[0]))
      }
      if (viewResponse) {
        sessionStorage.setItem('viewData', JSON.stringify(viewResponse))
      }
      setViewDataStore(viewResponse ?? JSON.parse(sessionStorage.getItem("viewData")))
    } catch (err) {
      console.log(err)
    }
  }

  //store data 
  useEffect(() => {
    storeData()
  }, [viewResponse])

  if (isLoading) return

  return (
    <div className={`page-wrapper !opacity-100`} >
      <div className='content flex flex-col gap-[16px]'>
        <Form resModel={resModel} actionData={actionData ?? JSON.parse(sessionStorage.getItem("actionData"))} viewData={viewData} id={id} isDisplayBreadcrumbs={true} vid={vid} formSubmitRef={formSubmitRef} isMainForm={true} />
      </div>
    </div >
  )
}

export default FormView
