import { useTranslation } from 'react-i18next';

export const useFormatDate = () => {
    const { t } = useTranslation();

    const getRelativeTime = (dateString) => {
        const secondsInMinute = 60;
        const minutesInHour = 60;
        const hoursInDay = 24;
        const daysInMonth = 30;
        const daysInYear = 365;

        const currentDate = new Date();
        const [day, month, year, hours, minutes, seconds] = dateString
            .split(/[- :]/)
            .map((part) => parseInt(part));

        const givenDate = new Date(year, month - 1, day, hours, minutes, seconds);
        const timeDifferenceInSeconds = Math.floor((currentDate - givenDate) / 1000);

        const secondsDiff = timeDifferenceInSeconds;
        const minutesDiff = Math.floor(secondsDiff / secondsInMinute);
        const hoursDiff = Math.floor(minutesDiff / minutesInHour);
        const daysDiff = Math.floor(hoursDiff / hoursInDay);
        const monthsDiff = Math.floor(daysDiff / daysInMonth);
        const yearsDiff = Math.floor(daysDiff / daysInYear);

        if (yearsDiff > 0) {
            return `${yearsDiff} ${t('noti-6')}`;
        } else if (monthsDiff > 0) {
            return `${monthsDiff} ${t('noti-5')}`;
        } else if (daysDiff > 0) {
            return `${daysDiff} ${t('noti-4')}`;
        } else if (hoursDiff > 0) {
            return `${hoursDiff} ${t('noti-3')}`;
        } else if (minutesDiff > 0) {
            return `${minutesDiff} ${t('noti-2')}`;
        } else {
            return `${secondsDiff} ${t('noti-1')}`;
        }
    };

    return { getRelativeTime };
};
