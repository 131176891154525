import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

const Loading = () => {
  const { t } = useTranslation()
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % 6);
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='my-auto flex-1 h-full flex justify-center items-center'>
      <div className='flex justify-center items-center'>
        <div className='flex justify-center flex-col items-center gap-12'>
          <div className="loading-container">
            {[...Array(6)].map((_, index) => (
              <div
                key={index}
                className={`loading-item ${index === activeIndex ? 'active' : ''}`}
              ></div>
            ))}
          </div>
          <div className='text-[rgba(45,45,45,1)] text-xl font-semibold tracking-[-1%]'>{t("loading")}</div>
        </div>
      </div>
    </div>
  );
}

export default Loading
