import React from 'react';
import { Controller } from 'react-hook-form';
import { formatFloatNumber } from '../../util/util';

const FloatField = ({
  name,
  value,
  readonly,
  placeholder,
  required,
  invisible,
  methods,
  isForm,
  handleOnchange,
  defaultValue,
  relation,
}) => {
  if (!isForm) {
    return <span>{formatFloatNumber(value ?? defaultValue)}</span>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={{
        required: required && !invisible,
        validate: (val) => !isNaN(parseFloat(val)) || 'Invalid number',
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <input
          value={value !== undefined && value !== null ? formatFloatNumber(value) : ''}
          onBlur={(e) => {
            const rawValue = e.target.value.replace(/,/g, '');
            const parsedValue = parseFloat(rawValue);
            if (!readonly && !isNaN(parsedValue)) {
              onBlur();
              handleOnchange(name, parsedValue);
            }
          }}
          onChange={(e) => {
            const rawValue = e.target.value.replace(/,/g, '');
            const parsedValue = parseFloat(rawValue);
            if (!readonly && !isNaN(parsedValue)) {
              onChange(parsedValue);
            }
          }}
          type="text"
          readOnly={readonly}
          disabled={readonly}
          placeholder={placeholder}
          required={!invisible && required}
          style={invisible ? { display: 'none' } : {}}
          className={`${!isForm ? '!bg-transparent focus-within:!border-none' : 'field'
            }`}
        />
      )}
    />
  );
};

export default FloatField;
