import React from 'react';
import ImageWithBasePath from '../general/ImageCustom';

const ImageField = ({ value, type }) => {
  if (!value) return

  if (type === "url") {
    return <img src={process.env.REACT_APP_DOMAIN + value} />
  }

  return (
    <div id='qr-code'>
      <ImageWithBasePath src={`data:image/png;base64,${value}`} />
    </div>
  )
}

export default ImageField
