import { useNavigate } from "react-router-dom"
import FieldView from "../../components/field/field-view"

const KanbanView = ({ data, name, vid, resModel, course }) => {

    const navigate = useNavigate()
    const handleClick = (item) => {
        navigate(`/form?vid=${vid}&model=${resModel}&id=${item.id}`)
    }
    if (name == "student_template") {
        return (
            <div className="flex flex-wrap justify-center items-start">
                {data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} className="cursor-pointer min-w-[350px] min-h-[133px] p-[8px] flex bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-[80px] h-full flex pl-[4px]">
                                {item.image_1920 ? <img src={item.image_1920} width={64} height={64} alt="avatar" /> : <img src="/assets/img/avatar/avatar_grey.png" width={64} height={64} alt="avatar" />}
                            </div>
                            <div className="flex flex-col gap-[4px]">
                                <div>{item.name}</div>
                                <div>{item.curr_year}</div>
                                <div>{item.mobile}</div>
                                <div>{item.email}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "faculty_template") {
        return (
            <div className="flex flex-wrap justify-center items-start">
                {data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} className="min-w-[350px] cursor-pointer min-h-[97px] flex bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-[95px] h-full flex pl-[4px] rounded-full">
                                {item.image_1920 ? <img src={item.image_1920} width={95} height={95} alt="avatar" /> : <img src="/assets/img/avatar/avatar_grey.png" width={95} height={95} alt="avatar" />}
                            </div>
                            <div className="flex flex-col gap-[4px] p-[8px]">
                                <div className="font-bold text-[16px]">{item.name}</div>
                                <div>{item.email}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "slide_channel_template") {
        return (
            <div className={`flex sm:flex-wrap items-center pt-[16px] px-[8px] ${data?.length > 2 ? "justify-center" : "justify-start"} `}>
                {data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} key={item.id} className="cursor-pointer w-full max-w-[533px] min-h-[133px] px-[16px] py-[8px] flex flex-col gap-[16px] bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-full h-full flex justify-between">
                                <div>{item.name}</div>
                                <div>more</div>
                            </div>
                            <div className="flex flex-col gap-[8px]">
                                <div className="flex items-center">
                                    <div className="w-1/2">
                                        <button className="bg-[#ED1C24] px-[10px] py-[5px] rounded-[4px] text-[#fff] font-semibold">
                                            Xem khóa học
                                        </button>
                                    </div>
                                    <div className="w-1/2 flex justify-between">
                                        <div className="flex flex-col gap-[4px] w-full">
                                            <div className="flex items-center justify-between w-full">
                                                <div>Lượt xem</div>
                                                <div>{item.total_views}</div>
                                            </div>
                                            <div className="flex items-center justify-between w-full">
                                                <div>Nội dung</div>
                                                <div>{item.total_time}</div>

                                            </div>
                                            <div className="flex items-center justify-between w-full">
                                                <div>Thời hạn</div>
                                                <div>{item.total_slides}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex items-center pb-[8px]">
                                    <div className="h-[42px] flex flex-col gap-[4px] w-1/4 border-r-[1px] items-center border-solid border-r-[#dee2e6]">
                                        <div>{item.members_invited_count}</div>
                                        <div>Đã mời</div>
                                    </div>
                                    <div className="h-[42px] flex flex-col gap-[4px] w-1/4 border-r-[1px] items-center border-solid border-r-[#dee2e6]">
                                        <div>{item.members_engaged_count}</div>
                                        <div>Đang học</div>
                                    </div>
                                    <div className="h-[42px] flex flex-col gap-[4px] w-1/4 border-r-[1px] items-center border-solid border-r-[#dee2e6]">
                                        <div>{item.members_completed_count}</div>
                                        <div>Hoàn tất</div>
                                    </div>
                                    <div className="h-[42px] flex flex-col gap-[4px] w-1/4 items-center">
                                        <div>{item.members_all_count}</div>
                                        <div>Tổng</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "slide_slide_view_kanban") {
        return (
            <div className="flex flex-wrap justify-start items-start px-[8px]">
                {data && data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} key={item.id} className="w-full cursor-pointer max-w-[350px] min-h-[143px] p-[8px] gap-[8px] flex bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-[95px] h-full flex rounded-full">
                                <img src="/assets/img/categories/contentDefault.jpg" width={95} alt="avatar" />
                            </div>
                            <div className="flex flex-col justify-between gap-[24px]">
                                <div className="flex flex-col gap-[6px]">
                                    <div className="font-bold">{item.name}</div>
                                    <FieldView
                                        {...course.field}
                                        idForm={false}
                                        string=''
                                        defaultValue={item[course.key]}
                                    />
                                    {item.tag_ids?.length > 0 && <div className="p-[4px] rounded-full bg-[#fae9e9] w-[70px] text-center text-[12px]">{item.tag_ids[0].display_name}</div>}
                                </div>
                                <div className="flex items-center gap-[12px] ">
                                    {item.slide_category && (<div className="flex items-center gap-[4px]"> <div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/document.svg" alt="document" />
                                    </div> {item.slide_category == "article" ? "Tài liệu" : "Bài viết"}</div>)}

                                    {item.completion_time && item.completion_time == "0" ? (<div className="flex items-center gap-[4px]"> <div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/clock.svg" alt="clock" />
                                    </div>  00:00</div>) : (<div className="flex items-center gap-[4px]"><div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/clock.svg" alt="clock" />
                                    </div>  0{item.completion_time}:00</div>)}
                                    {item.completion_time && item.completion_time == "0" ? (<div className="flex items-center gap-[4px]"><div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/question.svg" alt="clock" />
                                    </div> 0</div>) : (<div className="flex items-center gap-[4px]"> <div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/question.svg" alt="clock" />
                                    </div>{item.completion_time}</div>)}
                                    {item.total_views && item.total_views == "0" ? (<div className="flex items-center gap-[4px]"><div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/eye.svg" alt="clock" />
                                    </div> 0</div>) : (<div className="flex items-center gap-[4px]"><div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/eye.svg" alt="clock" />
                                    </div> {item.total_views}</div>)}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "helpdesk_ticket_kanban") {
        return (
            <div className="pt-[24px]">
                <div className="flex items-center gap-[24px]">
                    <div className="flex flex-col gap-[16px] w-full max-w-[350px] px-[16px]">
                        <div className="flex items-center justify-between">
                            <div className="font-bold">Mới</div>
                            <div className="font-bold">+</div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[16px] w-full max-w-[350px] px-[16px]">
                        <div className="flex items-center justify-between">
                            <div className="font-bold">Đang thực hiện</div>
                            <div className="font-bold">+</div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[16px] w-full max-w-[350px] px-[16px]">
                        <div className="flex items-center justify-between">
                            <div className="font-bold">Tạm dừng</div>
                            <div className="font-bold">+</div>
                        </div>
                    </div>
                </div>
                {
                    data && data.map((item) => {
                        return (
                            <div onClick={() => handleClick(item)} key={item.id} className="w-full cursor-pointer max-w-[350px] min-h-[143px] p-[8px] gap-[8px] flex bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                                <div className="w-[95px] h-full flex rounded-full">
                                    <img src="/assets/img/categories/contentDefault.jpg" width={95} alt="avatar" />
                                </div>
                                <div className="flex flex-col justify-between gap-[24px]">
                                    <div className="flex flex-col gap-[6px]">
                                        <div className="font-bold">{item.name}</div>
                                        <FieldView
                                            {...course.field}
                                            idForm={false}
                                            string=''
                                            defaultValue={item[course.key]}
                                        />
                                        {item.tag_ids?.length > 0 && <div className="p-[4px] rounded-full bg-[#fae9e9] w-[70px] text-center text-[12px]">{item.tag_ids[0].display_name}</div>}
                                    </div>
                                    <div className="flex items-center gap-[12px] ">
                                        {item.slide_category && (<div className="flex items-center gap-[4px]"> <div className="w-[16px] h-[16px]">
                                            <img src="/assets/img/avatar/document.svg" alt="document" />
                                        </div> {item.slide_category == "article" ? "Tài liệu" : "Bài viết"}</div>)}

                                        {item.completion_time && item.completion_time == "0" ? (<div className="flex items-center gap-[4px]"> <div className="w-[16px] h-[16px]">
                                            <img src="/assets/img/avatar/clock.svg" alt="clock" />
                                        </div>  00:00</div>) : (<div className="flex items-center gap-[4px]"><div className="w-[16px] h-[16px]">
                                            <img src="/assets/img/avatar/clock.svg" alt="clock" />
                                        </div>  0{item.completion_time}:00</div>)}
                                        {item.completion_time && item.completion_time == "0" ? (<div className="flex items-center gap-[4px]"><div className="w-[16px] h-[16px]">
                                            <img src="/assets/img/avatar/question.svg" alt="clock" />
                                        </div> 0</div>) : (<div className="flex items-center gap-[4px]"> <div className="w-[16px] h-[16px]">
                                            <img src="/assets/img/avatar/question.svg" alt="clock" />
                                        </div>{item.completion_time}</div>)}
                                        {item.total_views && item.total_views == "0" ? (<div className="flex items-center gap-[4px]"><div className="w-[16px] h-[16px]">
                                            <img src="/assets/img/avatar/eye.svg" alt="clock" />
                                        </div> 0</div>) : (<div className="flex items-center gap-[4px]"><div className="w-[16px] h-[16px]">
                                            <img src="/assets/img/avatar/eye.svg" alt="clock" />
                                        </div> {item.total_views}</div>)}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div >
        )
    } else {
        return (
            <div className="flex flex-wrap justify-center items-start">
                {data && data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} key={item.id} className="w-full cursor-pointer max-w-[350px] min-h-[97px] p-[8px] gap-[8px] flex bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-[64px] h-full flex rounded-full">
                                {item.books_image ? <img src={item.books_image} width={64} height={64} alt="avatar" /> : <img src="/assets/img/avatar/default_book.png" width={64} height={64} alt="avatar" />}
                            </div>
                            <div className="flex flex-col gap-[4px]">
                                <div className="font-bold text-[16px]">{item.books_code}</div>
                                <div>{item.name}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

}
export default KanbanView