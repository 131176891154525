import React, { Fragment, useEffect, useState } from 'react'
import { X } from 'react-feather'
import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import { useQuery } from '@tanstack/react-query'
import ViewServices from '../../core/services/view-service/view-service'
import Form from '../../pages/form-view/form'
import { useSearchParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { FIELD_TYPE } from '../../constant/field-type'
import ModelService from '../../core/services/model-service/model-service'
import { isObjectEmpty } from '../../util/util'

const ModalTreeViewForm = ({ onchangeData, resModel, context, title, handleCloseModal, index, tab, idForm }) => {
    const [indexRow, setIndexRow] = useState()
    const [searchParams] = useSearchParams()
    const modelParam = searchParams.get("model")
    const id = parseInt(searchParams.get("id"))
    const vid = parseInt(searchParams.get("vid"))
    const [formValues, setFormValues] = useState(onchangeData)
    const methods = useFormContext()

    const { data: viewResponse } = useQuery({
        queryKey: [`view-tree-form-${resModel}`],
        queryFn: () => ViewServices.getFieldView({
            resModel: resModel,
            views: [[false, "form"], [false, "tree"], [false, "search"]],
            context: context,
            id: false
        }),
        refetchOnWindowFocus: false,
    })

    const dataModel = viewResponse?.models?.[resModel]
    const fieldsObjectValue = dataModel && Object.values(dataModel).find(
        obj => obj.relation === modelParam
    );

    useEffect(() => {
        if (viewResponse) {
            // const fields = viewResponse?.views?.form?.fields?.map((field) => ({ ...dataModel?.[field?.name], ...field }))
            // const updatedField = appendNewAttribute(fields, {
            //     rootField: tab,
            //     index: onchangeData?.[tab?.name]?.length
            // })

            // setFieldsForm(updatedField)
            setIndexRow(index)
        }
    }, [viewResponse])

    const handleSubmit = () => {
        const updatedData = { ...onchangeData, ...formValues };
        const viewData = viewResponse;
        const resModelFields = viewData?.models?.[resModel];

        if (updatedData) {
            Object.keys(updatedData).forEach((key) => {
                const fieldType = resModelFields?.[key]?.type;

                if (fieldType === FIELD_TYPE.ONE2MANY || fieldType === FIELD_TYPE.MANY2MANY) {
                    const processedValue = (updatedData[key] ?? []).map((item) => {
                        if (isObjectEmpty(item)) return null;

                        if (Array.isArray(item) && item.length >= 3) {
                            return ModelService.toDataJS(item[2], viewData, resModel);
                        }
                        return item;
                    }).filter(Boolean);

                    methods.setValue(key, processedValue, { shouldDirty: true });
                } else {
                    methods.setValue(key, updatedData[key], { shouldDirty: true });
                }
            });
        }

        handleCloseModal();
    };


    const handleCancel = () => {
        handleCloseModal()
    }

    const handleOnchangeForm = (onchangeData) => {
        const { id } = onchangeData;

        setFormValues((pre) => {
            const updatedTabValues = [...(pre?.[tab?.name] || [])];

            if (id) {
                if (index !== -1) {
                    updatedTabValues[index] = { ...onchangeData };
                }
            } else updatedTabValues.push(onchangeData);

            return { ...pre, [tab?.name]: updatedTabValues };
        });
    };


    return (
        <Transition
            show={true}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            as={Fragment}
        >
            <Dialog onClose={() => handleCancel(indexRow)}>
                <DialogPanel>
                    <div className='fixed bottom-0 left-0 right-0 top-0 z-[100]'>
                        <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                        <div className='absolute inset-0 flex flex-col justify-center items-center px-5'>
                            <div className='relative z-[1] max-w-full my-[88px] p-4 flex flex-col gap-4 w-[1000px] transform rounded-3xl bg-[#FFF] max-h-[90%] h-fit '>
                                <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                    <div id="modal-title" className='text-[20px] font-semibold'>
                                        {title}
                                    </div>
                                    <button onClick={() => handleCancel(indexRow)} aria-label="Close">
                                        <X size={20} />
                                    </button>
                                </div>
                                <div className='flex flex-1 flex-col w-full overflow-auto relative'>
                                    <div className='w-full'>
                                        <Form
                                            resModel={resModel}
                                            actionData={{}}
                                            viewData={viewResponse}
                                            id={idForm ?? null}
                                            isDisplayBreadcrumbs={false}
                                            vid={vid}
                                            formSubmitRef={null}
                                            isMainForm={false}
                                            objectValue={{ [fieldsObjectValue?.name]: id }}
                                            parentHandleOnChange={handleOnchangeForm}
                                        />
                                    </div>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <button type='button' onClick={handleSubmit} className='button-primary'>Thêm</button>
                                    <button type='button' onClick={() => handleCancel(indexRow)} className='button-secondary'>Hủy</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </Transition>
    )
}

export default ModalTreeViewForm
