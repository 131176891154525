import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useHandleField from '../../components/form/model/main'
import ActionsView from '../../components/form/view/action-view'
import MainForm from '../../components/form/view/main-form'
import Loading from '../../components/general/Loading'
import Breadcrumbs from '../../components/toolbar/view'
import ModelService from '../../core/services/model-service/model-service'
import { FIELD_TYPE } from '../../constant/field-type'
import { VIEW_TYPE } from '../../constant/view-type'
import { evalJSONContext, filterFieldDirty, getFieldsOnChange, getSpecificationByFields, isObjectEmpty, mergeObjects, showErrorMessage, showSuccessMessage } from '../../util/util'
import useFormStore from '../../store/form'
import useHeaderStore from '../../store/header'
import Chatter from '../chatter/page'
import { matchDomains } from '../../core/domain/domain'

const Form = ({ resModel, actionData, viewData, id, vid, isDisplayBreadcrumbs = false, relationMappingData = {}, initialMappingData = {}, isNoteBookViewMode = false, isInModel = false, className, parentSpecification, parentFields, parentHandleOnChange, isMainForm, isButtonInside, handleCloseActions, objectValue = {} }) => {
  const [searchParams] = useSearchParams()
  const [onchangeData, setOnchangeData] = useState()
  const [fieldChange, setFieldChange] = useState([])
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isFade, setIsFade] = useState(false)
  const nameAction = actionData?.name
  const contextAction = actionData?.context ? evalJSONContext(actionData?.context) : {}
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const { setFormSubmitComponent } = useFormStore()
  const { rootContext } = useHeaderStore()
  const formSubmitRef = useRef(null)

  // fetch list field have onchange api and related
  const { data: fieldsOnchange } = useQuery({
    queryKey: [`field-onchange-${resModel}`, resModel],
    queryFn: () => ModelService.getFieldWithOnchange({ model: resModel }),
    refetchOnWindowFocus: false,
  })

  const chatter = viewData?.views?.form?.chatter

  const toolbar = viewData?.views?.tree?.toolbar
  const dataModel = viewData?.models?.[resModel]
  const fields = parentFields ?? viewData?.views?.form?.fields?.map((field) => ({ ...dataModel?.[field?.name], ...field }))
  const fieldsViewOnChange = fields && getFieldsOnChange(fields)

  const oeTitle = viewData?.views?.form?.oe_title || []
  const headerFiled = viewData?.views?.form?.header ? viewData?.views?.form?.header.filter((comp) => comp?.type_co === 'field') : []
  const tabFields = viewData?.views?.form?.tabs?.flatMap((tab) => tab.fields || []) || []
  const specification =
    (viewData &&
      getSpecificationByFields(
        [...Object.values(dataModel), ...fields, ...oeTitle, ...headerFiled, ...tabFields, ...viewData?.views?.form?.button_box, ...(viewData?.views?.form?.button_box?.map(button => button.field))],
        {},
        viewData,
        resModel
      ))

  const initVal = ModelService.toDataJS(onchangeData, viewData, resModel)
  const methods = useForm({
    mode: 'onBlur',
    values: isNoteBookViewMode ? { ...initVal, ...relationMappingData, ...initialMappingData } : initVal,
  })

  const formValues = methods.watch()
  const { dirtyFields } = methods.formState

  // fetch onchange default form data
  const fetchOnchange = async ({ id, model, specification, context, objVal, fieldChange }) => {
    try {
      const onchangeResponse = await ModelService.onChangeForm({
        ids: id ? [id] : [],
        model: model,
        specification: parentSpecification ?? specification,
        context: { ...rootContext, ...context },
        object: objVal,
        fieldChange: fieldChange,
      })
      return onchangeResponse?.value ?? onchangeResponse?.[0]
    } catch (err) {
      console.log(err)
    }
  }

  // fetch detail data record
  const fetchDetailData = async () => {
    try {
      const dataResponse = await ModelService.getDetailData({
        model: resModel,
        ids: id ? [id] : [],
        specification: parentSpecification ?? specification,
        context: { ...rootContext, ...contextAction },
      })
      if (dataResponse.length > 0) {
        return dataResponse[0]
      }

      return {}
    } catch (err) {
      console.log(err)
    }
  }

  // get default form value 
  const { isLoading: isLoadingFields, isPending, isFetched: isQueryFetched, isFetching, refetch: refetchDataForm } = useQuery({
    queryKey: [`formData-${resModel}-${id}`, id, vid, resModel],
    queryFn: async () => {
      if (!id) {
        const onchangeResponse = await fetchOnchange({
          ids: id ? [id] : [],
          model: resModel,
          specification: specification,
          context: contextAction,
          objVal: objectValue,
          fieldChange: fieldChange,
        });
        setOnchangeData(onchangeResponse)
      } else {
        const detailData = await fetchDetailData()
        setOnchangeData(detailData)
      }

      return {}
    },
    enabled: !!specification,
    refetchOnWindowFocus: false,
  })

  // handle submit form
  const handleFormSubmit = async () => {
    try {
      setLoading(true)
      const data = ModelService.parseORMOdoo(
        filterFieldDirty(id, viewData, formValues, dirtyFields, resModel, onchangeData)
      )

      const response = await ModelService.saveForm({
        ids: id ? [id] : [],
        resModel: resModel,
        data: data,
        specification: specification,
        context: contextAction,
      })
      if (response && response.length > 0 && isMainForm) {
        searchParams.set('id', response?.[0]?.id)
        if (isInModel) {
          refetchDataForm()
        } else {
          const _url = `/${VIEW_TYPE.FORM}?${searchParams.toString()}`
          navigate(_url)
        }
      }
      setLoading(false)
      showSuccessMessage(!id ? `${t('create_success')}` : `${t('update_success')}`)
    } catch (error) {
      console.log(error)
      setLoading(false)
      showErrorMessage(!id ? `${t('create_fail')}` : `${t('update_fail')}`)
    }
  }

  useEffect(() => {
    if (!isFetching && !isPending) {
      setTimeout(() => {
        setIsFade(true)
      }, 100)
    }
  }, [isFetching, isPending])

  //handle delete record
  const handleDelete = async () => {
    const result = window.confirm("Bạn có chắc chắn muốn xóa không?");
    if (result) {
      setLoadingDelete(true)
      try {
        await ModelService.deleteByForm({ ids: [id], model: resModel })
        setLoadingDelete(false)
        showSuccessMessage(`${t('delete_success')}`)
        navigate(`/list?vid=${vid}&model=${resModel}`);
      } catch (err) {
        setLoadingDelete(false)
        showErrorMessage(`${t('delete_fail')}`)
      }
    }
  }

  //handle onchange formValues
  const handleOnchange = (nameField, value) => {
    if (fieldsOnchange?.includes(nameField) || fieldsViewOnChange?.includes(nameField)) {
      const fetchData = async () => {
        const obj = {
          ...objectValue,
          ...ModelService.parseORMOdoo(filterFieldDirty(id, viewData, formValues, dirtyFields, resModel, onchangeData)),
          [nameField]: value
        }
        const dataOnchange = await fetchOnchange({
          id: id,
          model: resModel,
          specification: specification,
          context: contextAction,
          objVal: ModelService.parseORMOdoo(obj),
          fieldChange: [nameField]
        })

        // const updatedData = { ...formValues, [nameField]: value, ...ModelService.toDataJS(dataOnchange, viewData, resModel) };
        const updatedData = mergeObjects(ModelService.toDataJS(dataOnchange, viewData, resModel), { ...formValues, [nameField]: value });

        const dataOnchangeJS = ModelService.toDataJS(updatedData, viewData, resModel)

        if (dataOnchangeJS) {
          Object.keys(dataOnchangeJS).forEach((key) => {
            if ((viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.ONE2MANY) || (viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.MANY2MANY)) {
              methods.setValue(key, (dataOnchangeJS[key] ??= []).map((item) => {
                if (isObjectEmpty(item)) return
                if (Array.isArray(item) && item?.length >= 3) {
                  return ModelService.toDataJS(item[2], viewData, resModel)
                }

                return item
              }).filter(Boolean))
            } else {
              methods.setValue(key, dataOnchangeJS[key])
            }
          })
          parentHandleOnChange && parentHandleOnChange(dataOnchangeJS)
        }
      }

      fetchData()
    }
  }

  //get field items
  const { fieldList } = useHandleField({
    fields: (fields && oeTitle) && [...oeTitle, ...fields],
    viewData: viewData,
    resModel: resModel,
    onchangeData: formValues,
    setOnchangeData: setOnchangeData,
    handleOnchange: handleOnchange,
  })

  useEffect(() => {
    setFormSubmitComponent(resModel, formSubmitRef)
  }, [formSubmitRef?.current])

  const onSubmit = async (e) => {
    e.stopPropagation()
    methods.handleSubmit(handleFormSubmit)()
  }

  return (
    <>
      {
        ((!isLoadingFields && isQueryFetched)) ?
          <FormProvider {...methods}>
            {isDisplayBreadcrumbs && <Breadcrumbs
              id={id}
              title={nameAction}
              subTitle={!id ? t('new') : formValues?.name || formValues?.id}
              viewData={viewData}
              actionData={actionData}
              dataToolbar={toolbar}
              vid={vid}
              model={resModel}
              isInForm={true}
              funcReset={() => {
                if (methods.formState.isDirty) {
                  methods.reset()
                  showSuccessMessage("Hoàn tác thành công")
                }
              }}
              loading={loading}
              loadingDelete={loadingDelete}
              onDelete={handleDelete}
              formSubmitRef={formSubmitRef}
              nameActionSave={id ? t('update_button') : t('save')}
              refetch={refetchDataForm}
              formValues={formValues}
              formSpecification={specification}
              handleOnchange={handleOnchange}
            />
            }
            <form ref={formSubmitRef} className={`${isFade && "fade-in"} relative form w-full`} onSubmit={(event) => { onSubmit(event) }}
            >
              <div className={`flex flex-col gap-2 md:!gap-4 w-full`}>
                {viewData?.views?.form?.header && viewData?.views?.form?.header?.length > 0 && <ActionsView
                  formSubmitRef={formSubmitRef}
                  isForm={true}
                  refetch={refetchDataForm}
                  actions={viewData?.views?.form?.header}
                  resModel={resModel}
                  viewData={viewData}
                  data={formValues}
                  isMainForm={isMainForm}
                  handleOnchange={handleOnchange}
                  formSpecification={specification}
                />}
                <MainForm
                  id={id}
                  handleOnchange={handleOnchange}
                  fieldList={fieldList}
                  viewData={viewData}
                  onchangeData={formValues}
                  resModel={resModel}
                  context={actionData?.context}
                  title={nameAction}
                  className={className}
                  setOnchangeData={setOnchangeData}
                />
                {viewData?.views?.form?.footer && viewData?.views?.form?.footer?.length > 0 &&
                  <ActionsView
                    isForm={false}
                    specification={specification}
                    actions={viewData?.views?.form?.footer ?? []}
                    resModel={resModel}
                    viewData={viewData}
                    data={formValues}
                    formSubmitRef={formSubmitRef}
                    isMainForm={isMainForm}
                    isButtonInside={isButtonInside}
                    handleCloseActions={handleCloseActions}
                  />
                }
              </div>
              {chatter && !matchDomains(chatter?.invisible) && <Chatter />}
            </form>
          </FormProvider> :
          <Loading />
      }
    </>
  )
}

export default Form
