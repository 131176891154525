import React from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import ViewServices from '../../core/services/view-service/view-service'
import { evalJSONDomain } from '../../util/util'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import InfoWidget, { Avatar } from './Info'
import useHeaderStore from '../../store/header'
import useSidebar from '../../store/sidebar'
import { X } from 'react-feather'

const CustomMultiValue = (props) => {
  const { selectProps, data } = props
  const { relation } = selectProps
  const { menuList } = useSidebar()

  const actionId = menuList?.flatMap(item =>
    item?.child_id.filter(childItem =>
      childItem?.is_display && childItem?.action?.res_model === relation
    )
  )?.[0]?.action?.id;

  return (
    <div className='flex items-center gap-2 relative mr-2 group'>
      <Avatar id={data?.id} fullName={data?.label ?? data?.display_name} relation={relation} isForm={true} vid={actionId?.id} />
      <span
        className='absolute -top-1 -right-1 font-medium bg-white rounded-full !shadow-lg hidden group-hover:block'
        onClick={(e) => {
          e.stopPropagation();
          props.removeProps.onClick();
        }}
      >
        <X size={12} />
      </span>
    </div>
  );
};

const Many2ManyTag = ({
  relation,
  value,
  name,
  methods,
  readonly,
  placeholder,
  required,
  invisible,
  defaultValue,
  isForm,
  handleOnchange,
  domain,
  lang
}) => {
  const { t } = useTranslation()
  const { rootContext } = useHeaderStore()

  const domainObject = evalJSONDomain(domain, methods?.getValues() || {})
  const { data: dataOfSelection } = useQuery({
    queryKey: [`data_${relation}`, domainObject, lang],
    queryFn: () => ViewServices.getSelectionItem({ model: relation, domain: domainObject, context: rootContext }),
    refetchOnWindowFocus: false
  }
  )

  const customNoOptionsMessage = () => t("no_option")
  const tranfer = (data) => {
    return data?.map((val) => ({
      id: val.value,
      display_name: val.label,
    })) || []
  }

  const options =
    dataOfSelection?.records?.map((val) => ({
      value: val.id,
      label: val.name ?? val.display_name,
      ...val
    })) || []

  const optionValue = value?.map(item => ({ ...item, value: item.id, label: item.name ?? item.display_name }))

  if (!isForm) {
    return <InfoWidget value={optionValue || null} isForm={isForm} />
  } else {
    const isUser = relation === "res.users" || relation === "res.partner"
    return (
      <Controller
        name={name}
        control={methods.control}
        render={({ field }) => {
          return <Select
            relation={relation}
            key={field?.value}
            options={options}
            noOptionsMessage={customNoOptionsMessage}
            isMulti
            value={options.filter((option) => (field.value ??= [])?.some(item => (item?.id) === option.value)) || null}
            onChange={(selectedOptions) => {
              field.onChange(tranfer(selectedOptions))
              handleOnchange(name, tranfer(selectedOptions))
            }}
            classNames={{
              valueContainer: () => "overflow-unset !px-0",
              control: () => isForm
                ? `field h-auto !py-1 ${isUser ? "" : "custom-select"}`
                : "field-in-tree custom-select",
            }}
            // isDisabled={readonly}
            components={isUser ? { MultiValue: CustomMultiValue } : {}}
            placeholder={t("choose_place")}
            required={!invisible && required}
          />
        }}
      />
    )
  }
}

export default Many2ManyTag
