import viLocale from '@fullcalendar/core/locales/vi';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import ViewServices from '../../core/services/view-service/view-service';
import useHeaderStore from '../../store/header';
import ModalCalendar from '../modal/ModalCalendar';
import { format } from 'date-fns';
import { evalJSONContext, filterFieldDirty, getSpecificationByFields } from '../../util/util';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ModelService from '../../core/services/model-service/model-service';
import { OBJECT_POSITION } from '../../constant/componen-type';
import { FIELD_TYPE } from '../../constant/field-type';
import { useSearchParams } from 'react-router-dom';

// generate events
const eventsTest = [
  { title: 'event 1', start: '2024-11-11T10:00:00', end: '2024-11-11T12:00:00' },
  { title: 'event 1', start: '2024-11-11T10:00:00', end: '2024-11-11T12:00:00' },
];

export default function Calendar({ events, tabsData, id, onchangeData }) {
  const dayKeyMap = tabsData?.reduce((acc, tab, index) => {
    const fieldName = tab?.fields?.[0]?.name;
    if (fieldName) {
      acc[index] = fieldName;
    }
    return acc;
  }, {});

  const [searchParams] = useSearchParams()
  const idForm = parseInt(searchParams.get("id"))
  const viewData = JSON.parse(sessionStorage.getItem('viewData'))

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [extendProps, setExtendProps] = useState(null)
  const [defaultTab, setDefaultTab] = useState(tabsData?.[0].fields?.[0])

  const [initialMappingData, setInitialMappingData] = useState(tabsData?.[0])
  const { rootContext, } = useHeaderStore()
  const notebookViewMode = viewData?.views?.form?.notebook_view_mode?.calendar
  const { res_model, views, related_field } = notebookViewMode

  const context = defaultTab?.context ? evalJSONContext(defaultTab?.context) : {}

  const { data: fieldsOnchange } = useQuery({
    queryKey: [`field-onchange-${res_model}`, res_model],
    queryFn: () => ModelService.getFieldWithOnchange({ model: res_model }),
    refetchOnWindowFocus: false,
  })

  const methods = useFormContext()
  const { append } = useFieldArray({
    control: methods?.control,
    name: defaultTab?.name,
  });
  const fieldArrayValues = methods?.watch(defaultTab?.name) || [];
  const { dirtyFields } = methods.formState

  const specification = getSpecificationByFields(defaultTab?.fields, {}, viewData, res_model)
  const objVal = related_field ? { [related_field]: onchangeData } : {}

  const { data: listView } = useQuery({
    queryKey: [`view-${res_model}`,],
    queryFn: () => ViewServices.getFieldView({
      resModel: res_model,
      views: [views],
      context: { ...rootContext, ...context }
    }),
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })

  const handleAppend = async (id) => {
    try {
      if (!id) {
        const onchangeResponse = await fetchOnchange({ id, model: res_model, specification, context: { ...rootContext, ...context }, objVal: objVal })
        if (onchangeResponse) {
          append(ModelService.toDataJS(onchangeResponse, viewData, res_model))
        }
      }

    } catch (err) {
      console.log(err);
    }
  }

  const handleSelect = (info) => {
    const isDateStart = listView?.views?.calendar?.date_start
    const isDateStop = listView?.views?.calendar?.date_stop
    if (isDateStart && isDateStop) {
      setInitialMappingData({
        date: format(info?.startStr, 'yyyy-MM-dd'),
        week_day: new Date(info?.startStr)?.getDay()?.toString(),
        [listView?.views?.calendar?.date_start]: format(info?.startStr, 'HH'),
        [listView?.views?.calendar?.date_stop]: format(info?.endStr, 'HH'),
      })
    } else {
      const dateField = listView?.views?.calendar?.fields?.find(field => evalJSONContext(field.options)?.type === 'date')
      const weekdayField = listView?.views?.calendar?.fields?.find(field => evalJSONContext(field.options)?.type === 'week')

      const result = {
        // faculty_id: null,
        // period_id: null,
        // subject: null,
        date: format(info?.startStr, 'yyyy-MM-dd'),
        week_day: new Date(info?.startStr)?.getDay()?.toString(),
        time_from: format(info?.startStr, 'HH'),
        time_till: format(info?.endStr, 'HH'),
      }
      if (dateField) result[dateField?.name] = format(info?.startStr, 'yyyy-MM-dd')
      if (weekdayField) result[weekdayField?.name] = new Date(info?.startStr)?.getDay()?.toString()
      setInitialMappingData(result)
    }

    if (listView?.views?.calendar?.quick_create) {
      handleAppend()
      setIsOpenModal(true)
    }
  };

  const handleEventChange = (info) => {
    const selectedDay = info?.event.start.getDay();
    const nameOfDay = dayKeyMap[(selectedDay + 6) % 7]
    setExtendProps((pre) => ({ ...pre, ...info?.event?._def?.extendedProps, key: nameOfDay }))
    setIsOpenModal(true)
  };

  const fetchOnchange = async ({ id, model, specification, context, objVal, fieldChange }) => {
    try {
      const onchangeResponse = await ModelService.onChangeForm({
        ids: id ? [id] : [],
        model: model,
        specification: specification,
        context: context,
        object: objVal,
        fieldChange: fieldChange,
      })
      return onchangeResponse?.value
    } catch (err) {
      console.log(err)
    }
  }

  const handleOnchangeCalendar = (nameField, value) => {
    const nameItems = nameField.split('.')
    const rootField = nameItems[0]
    const index = parseInt(nameItems[1])
    const name = nameItems[2]
    const data = ModelService.parseORMOdoo(fieldArrayValues[index])

    const fetchData = async () => {
      const dirtyFieldTreeForm = dirtyFields?.[rootField][index]

      const obj = {
        ...objVal,
        ...ModelService.parseORMOdoo(filterFieldDirty(idForm, viewData, data, dirtyFieldTreeForm, res_model, onchangeData)),
        [name]: value
      }

      const dataOnchange = await fetchOnchange({
        id: data?.id,
        model: res_model,
        specification: specification,
        context: { ...rootContext, ...context },
        objVal: ModelService.toDataJS(obj),
        fieldChange: [name]
      })

      const updatedData = { ...data, [name]: value, ...ModelService.toDataJS(dataOnchange) };
      const dataOnchangeJS = ModelService.toDataJS(updatedData, viewData, res_model)
      if (dataOnchangeJS) {
        Object.keys(dataOnchangeJS).forEach((key) => {
          if ((viewData?.models?.[res_model]?.[key]?.type === FIELD_TYPE.ONE2MANY) || (viewData?.models?.[res_model]?.[key]?.type === FIELD_TYPE.MANY2MANY)) {
            methods.setValue(`${rootField}.${index}.${key}`, (dataOnchangeJS[key] ??= []).map((item) => {
              const subRelation = viewData?.models?.[res_model]?.[key]?.relation
              if (viewData?.models?.[subRelation]) {
                if (item?.length >= 3) {
                  return ModelService.toDataJS(item[OBJECT_POSITION], viewData, subRelation)
                } else {
                  return ModelService.toDataJS(item, viewData, subRelation)
                }
              } else {
                if (item?.length >= 3) {
                  return item[OBJECT_POSITION]
                } else {
                  return item
                }
              }
            }), { shouldDirty: true })
          } else {
            methods.setValue(`${rootField}.${index}.${key}`, dataOnchangeJS[key], { shouldDirty: true })
          }
        })
      }
    }

    if (fieldsOnchange?.includes(name)) {
      fetchData()
    }
  }

  const renderEventContent = (eventInfo) => {
    return (
      <div className='text-[11px]'>
        <h3 className=' text-[12px] text-white line-clamp-2'>{eventInfo.event.title}</h3>
        {extendProps?.classroom_id && <p className='text-[10px] text-gray-200 line-clamp-1'>Phòng {extendProps?.classroom_id}</p>}
        <p className='text-[10px] text-gray-200 line-clamp-1'>{eventInfo.timeText}</p>
      </div >
    )
  };

  return (
    <div className="custom-calendar-header">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        selectable={true}
        headerToolbar={{
          left: 'today',
          center: 'title',
          right: 'prev,next',
          // right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }}
        views={{
          timeGridWeek: {
            columnHeaderText: (date) => {
              const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
              return dayNames[date.getUTCDay()];
            },
          }
        }}
        select={info => handleSelect(info)}
        selectAllow={selectInfo => {
          const start = selectInfo.start;
          const end = selectInfo.end;
          return start.getDate() === end.getDate() && start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear();
        }}
        events={events}
        eventClick={(info) => handleEventChange(info)}
        locale={viLocale}
        eventContent={renderEventContent}
      />

      {isOpenModal && <ModalCalendar
        id={extendProps?.itemId}
        name={extendProps?.key}
        resModel={res_model}
        initialMappingData={initialMappingData}
        rootContext={{ ...rootContext, ...context }}
        relationMappingData={{ [related_field]: id }}
        quickCreateViewId={listView?.views?.calendar?.quick_create_view_id}
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        handleOnchange={handleOnchangeCalendar}
        onchangeData={onchangeData}
        fields={defaultTab?.fields?.map((field) => ({ ...field, defaultValue: initialMappingData?.[field.name], rootField: defaultTab, index: fieldArrayValues.length - 1 }))}
      />
      }
    </div>
  );
}