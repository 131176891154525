import { useSearchParams } from "react-router-dom";
import { Domain } from "../../../core/domain/domain";
import useSearchStore from "../../../store/search";
import useTableStore from "../../../store/table";
import { SEARCH_TYPE } from "../../../constant/search-type";
import { checkUniqueItemAppendArray } from "../../../util/util";
import useHeaderStore from "../../../store/header";

const useSearch = () => {
    const [searchParams] = useSearchParams()
    const vid = searchParams.get('vid')
    const { fields } = useTableStore()
    const { rootContext } = useHeaderStore()
    const sessionContext = sessionStorage.getItem("actionData")
    const { tableHead, searchString, setSearchString, hoveredIndexSearchList, setHoveredIndexSearchList, setSelectedTags, selectedTags, firstDomain, searchMap: hashMap, groupByDomain, setGroupByDomain, typeFieldsSearch, modelSearch } = useSearchStore()

    const formatDomain = () => {
        const domain = [];

        if (firstDomain?.length > 0) {
            if (hashMap.size > 0) {
                domain.push("&")
            }
            domain.push(firstDomain)
        }

        Array.from(hashMap.keys()).map((key, keyIndex) => {
            if (hashMap.size > 1 && keyIndex < hashMap.size - 1) {
                domain.push("&")
            }
            const valuesOfKey = hashMap.get(key)
            valuesOfKey?.forEach((value, index) => {
                if (index < valuesOfKey.length - 1) {
                    domain.push("|")
                }

                if (value.domain) {
                    domain.push(...value.domain)
                    return
                }
                domain.push([value.name, value.operator ?? "ilike", value.value])
            })
        })

        return domain
    }

    const setTagSearch = () => {
        const tagsSearch = [...hashMap.values()].map(objValues => {
            const { title, name, groupIndex, type } = objValues[0]
            const values = objValues.map((objValue) => objValue.value)
            return { title, name: groupIndex ?? name, values, type }
        })

        setSelectedTags(groupByDomain ? [...tagsSearch, groupByDomain] : [...tagsSearch])
        setSearchString('')
    };

    const removeItemsFromList = (key, item) => {
        if (item) {
            let values = hashMap.get(key);
            if (values) {
                values = values.filter(value => value.name !== item.name);
                if (values.length === 0) hashMap.delete(key);
                else hashMap.set(key, values);
            }
            return
        }
        if (hashMap.has(key)) {
            hashMap.delete(key);
        }
    }

    const addItemToList = (key, newItem) => {
        if (hashMap.has(key)) {
            const list = hashMap.get(key);
            const isExistSearch = list.find(item => item.value === newItem.value)
            if (!isExistSearch) {
                hashMap.get(key).push(newItem);
            }
        } else {
            hashMap.set(key, [newItem]);
        }
        setTagSearch()
    }

    const handleAddTagSearch = (tag) => {
        const { name, icon, domain, groupIndex, value, type, title, context } = tag;
        const domainFormat = new Domain(domain)

        if (type === SEARCH_TYPE.FILTER_BY) {
            addItemToList(groupIndex, { ...tag, domain: domain ? domainFormat.toList({ ...rootContext, ...sessionContext?.context }) : null })
        } else if (type === SEARCH_TYPE.SEARCH_BY) {
            addItemToList(name, { ...tag, domain: domain ? domainFormat.toList({ ...rootContext, ...sessionContext?.context, self: value }) : null })
        }
        else if (type === SEARCH_TYPE.GROUP_BY) {
            const values = checkUniqueItemAppendArray(groupByDomain?.values ?? [], value)
            const contexts = checkUniqueItemAppendArray(groupByDomain?.contexts ?? [], context, SEARCH_TYPE.GROUP_BY)

            const fieldsGroup = [...new Set(fields[`${vid}_${modelSearch}${typeFieldsSearch === "many2many" ? `_popup${typeFieldsSearch}` : ""}`].map(item => item.name))];

            const groupByTag = { title, values, type, contexts, fields: fieldsGroup }
            setGroupByDomain(groupByTag)

            const updateSelectedTags = selectedTags.filter(item => item.type !== SEARCH_TYPE.GROUP_BY)
            setSelectedTags([...updateSelectedTags, groupByTag])
        }
    }

    const onKeyDown = (e) => {
        switch (e.key) {
            case 'Backspace':
                if (searchString === '' && selectedTags.length > 0) {
                    setSelectedTags(selectedTags?.slice(0, -1))
                    const lastKey = Array.from(hashMap.keys()).pop();
                    if (lastKey !== undefined) {
                        hashMap.delete(lastKey);
                    }
                }
                break
            case 'ArrowDown':
                setHoveredIndexSearchList((hoveredIndexSearchList < tableHead.length - 1 ? hoveredIndexSearchList + 1 : hoveredIndexSearchList))
                break
            case 'ArrowUp':
                e.preventDefault()
                setHoveredIndexSearchList((hoveredIndexSearchList > 0 ? hoveredIndexSearchList - 1 : hoveredIndexSearchList))
                break
            case 'Enter':
                if (searchString === "") return
                handleAddTagSearch({
                    title: tableHead[hoveredIndexSearchList].title,
                    name: tableHead[hoveredIndexSearchList].name,
                    value: searchString,
                    type: SEARCH_TYPE.SEARCH_BY,
                    domain: tableHead[hoveredIndexSearchList].filter_domain,
                    operator: tableHead[hoveredIndexSearchList].operator,
                    ...tableHead[hoveredIndexSearchList]
                })
                break
            default:
                break
        }
    }

    const handleMouseEnter = (index) => {
        setHoveredIndexSearchList(index)
    }

    const handleMouseLeave = () => {
        setHoveredIndexSearchList(null)
    }

    const onChangeSearchInput = (search_string) => {
        setSearchString(search_string)
        setHoveredIndexSearchList(0)
    }

    const removeSelectedTag = (name, item, type) => {
        removeItemsFromList(name, item)
        setTagSearch()
    }

    const clearSearch = () => {
        hashMap.clear()
        setSelectedTags([])
    }

    return {
        handleAddTagSearch,
        handleMouseEnter,
        handleMouseLeave,
        onKeyDown,
        onChangeSearchInput,
        removeSelectedTag,
        formatDomain,
        clearSearch
    }
}

export default useSearch
