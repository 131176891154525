import MessageContent from "./MessageContent"
import ListMessage from "./ListMessage"
import { useQuery } from "@tanstack/react-query"
import ChatterAction from "../../core/services/chatter"
import { useSearchParams } from "react-router-dom"

const Chatter = () => {
    const [searchParams] = useSearchParams()
    const resModel = searchParams.get('model')
    const id = searchParams.get('id')

    const { data: listComment, refetch } = useQuery({
        queryKey: [`get-list-comment`],
        queryFn: () => ChatterAction.getComment({
            thread_model: resModel,
            thread_id: id,
            limit: 30,
        }),
        refetchOnWindowFocus: false,
    })

    const handleAfterSubmit = () => {
        refetch()
    }

    return (
        <div className="flex flex-col gap-[24px] mt-3">
            <div className="flex flex-col gap-[16px]">
                <MessageContent handleAfterSubmit={handleAfterSubmit} />
            </div>
            <ListMessage messages={listComment?.messages || []} handleAfterSubmit={handleAfterSubmit} />
        </div>
    )
}
export default Chatter