import React from 'react';
import { useTranslation } from 'react-i18next';

const TableFooter = ({ columns = [], funcAddLine, dataResponseLength }) => {
    const { t } = useTranslation()
    const emptyData = dataResponseLength <= 0

    return (
        <tfoot>
            <tr className={`relative ${emptyData ? " h-20" : "h-14"}`}>
                <td className={`absolute left-0 p-0 right-0 flex items-center ${emptyData ? "justify-center" : "justify-start"}`}>
                    <button type='button' onClick={() => funcAddLine()} className={`button-primary m-2 flex ${!emptyData ? "bg-white border border-primary text-primary" : ""}  `}>
                        <svg className='text-primary' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path className={`${!emptyData ? "fill-primary" : "fill-white"} `} d="M15 10.625H5C4.65833 10.625 4.375 10.3417 4.375 10C4.375 9.65833 4.65833 9.375 5 9.375H15C15.3417 9.375 15.625 9.65833 15.625 10C15.625 10.3417 15.3417 10.625 15 10.625Z" />
                            <path className={`${!emptyData ? "fill-primary" : "fill-white"} `} d="M10 15.625C9.65833 15.625 9.375 15.3417 9.375 15V5C9.375 4.65833 9.65833 4.375 10 4.375C10.3417 4.375 10.625 4.65833 10.625 5V15C10.625 15.3417 10.3417 15.625 10 15.625Z" />
                        </svg>{t('add_line')}
                    </button>
                </td>
            </tr>
        </tfoot>
    )
}

export default TableFooter