import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionService from "../core/services/actions";
import { getSubdomain, showErrorMessage } from "../util/util";

const useActionHandler = ({ context, resModel, dataForm, refetch, }) => {
  const hostname = window.location.hostname;
  const [loading, setLoading] = useState(false);
  const [modalAction, setModalAction] = useState({ status: false, id: null, res_model: null, views: null, display_name: "", res_id: null });

  const handleOpenModal = async ({ actionId, ...params }) => {
    setModalAction(prev => ({ ...prev, ...params, status: true, idModal: actionId }));
  };

  const handleCloseActions = () => {
    setModalAction(prev => ({ ...prev, status: false, id: null, res_model: null, views: null, display_name: "", res_id: null }));
    refetch && refetch();
  };

  const handleOpenNewPage = async ({ url }) => {
    if (!url) return showErrorMessage('No such URL');
    window.open(`${process.env.REACT_APP_DOMAIN}${url}&db=${getSubdomain(hostname)}`, '_blank')
  };

  const resultLoadAction = {
    "ir.actions.act_window": (params) => handleOpenModal(params),
    "ir.actions.server": (params) => handleRunAction(params),
    "ir.actions.act_url": ({ url }) => handleOpenNewPage({ url }),
  };

  const resultRunAction = {
    "ir.actions.act_url": ({ url }) => handleOpenNewPage({ url }),
    "ir.actions.act_window": (params) => handleOpenModal({ ...params, actionId: null }),
  };

  const handleRunAction = async ({ actionId }) => {
    if (!actionId) return showErrorMessage('No such report');
    setLoading(true);
    try {
      const actionResponse = await ActionService.runAction({ idAction: actionId, idDetail: dataForm?.id, model: resModel });
      resultRunAction[actionResponse?.result?.type]({ actionId, actionResult: actionResponse?.result, formValues: context, url: actionResponse?.url });
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleButtonAction = async (actionId) => {
    if (!actionId) return showErrorMessage('No such report');
    setLoading(true);
    try {
      const actionResponse = await ActionService.loadAction({ idAction: actionId, idDetail: dataForm?.id, model: resModel });
      resultLoadAction[actionResponse?.result?.type]({ actionId, actionResult: actionResponse?.result, formValues: context });
    } catch (err) {
      showErrorMessage(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    modalAction,
    setModalAction,
    resultRunAction,
    resultLoadAction,
    handleButtonAction,
    handleCloseActions
  };
};

export default useActionHandler;