import React, { useState } from 'react'
import useTableStore from '../../../store/table'
import { useSearchParams } from 'react-router-dom'
import { useClickOutside } from '../../../hooks/useClickOutside'

export const TableFilterPopup = ({ model, visibleOptionalFields = [], tabIndex, type, openTableFilter }) => {
  const [searchParams] = useSearchParams()
  const vid = searchParams.get("vid")
  const { fields, setFields } = useTableStore()

  const handleClick = (data) => {
    const optional = data?.optional === 'show' ? 'hide' : 'show'
    const fieldsCache = (tabIndex === undefined && type !== "many2many") ? fields[`${vid}_${model}`] : type === "many2many" ? fields[`${vid}_${model}_popup${type}`] : fields[`${vid}_${model}_tab${tabIndex}`]
    const newData = fieldsCache?.map((item) => (item.name === data.name ? { ...item, optional: optional } : item))

    if (type === "many2many") {
      setFields({ ...fields, [`${vid}_${model}_popup${type}`]: newData })
    }
    else if (tabIndex === undefined) {
      setFields({ ...fields, [`${vid}_${model}`]: newData })
    }
    else {
      setFields({ ...fields, [`${vid}_${model}_tab${tabIndex}`]: newData })
    }
  }

  return (
    openTableFilter &&
    <div className='absolute right-3 top-[40px] z-[9999] flex w-[250px] max-h-[40vh] overflow-auto flex-col gap-[16px] rounded-[8px] bg-[#fff] px-[24px] py-[16px] shadow-md'>
      {visibleOptionalFields?.map((item) => {
        return (
          <label
            htmlFor={`${item.name}`}
            key={item.name}
            className='flex items-center gap-[8px]'
          >
            <input type='checkbox' id={`${item.name}`} onChange={() => handleClick(item)} checked={item.optional !== 'hide'} />
            {item.string}
          </label>
        )
      })}
    </div>
  )
}

const TableFilter = ({ model, tabIndex, type, visibleOptionalFields }) => {
  const [openTableFilter, setOpenTableFilter] = useState()
  const filterRef = useClickOutside(() => {
    if (openTableFilter) {
      setOpenTableFilter(false)
    }
  })

  return (
    visibleOptionalFields?.length > 0 &&
    <div ref={filterRef} className='w-fit absolute top-[10px] right-[10px] ms-auto'>
      <button type='button' className='bg-white size-8 p-2 border border-[DEE7DD] rounded-lg' onClick={() => {
        setOpenTableFilter(!openTableFilter)
      }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M14.6665 4.83301H10.6665C10.3932 4.83301 10.1665 4.60634 10.1665 4.33301C10.1665 4.05967 10.3932 3.83301 10.6665 3.83301H14.6665C14.9398 3.83301 15.1665 4.05967 15.1665 4.33301C15.1665 4.60634 14.9398 4.83301 14.6665 4.83301Z" fill="#1F1F1F" />
          <path d="M4.00016 4.83301H1.3335C1.06016 4.83301 0.833496 4.60634 0.833496 4.33301C0.833496 4.05967 1.06016 3.83301 1.3335 3.83301H4.00016C4.2735 3.83301 4.50016 4.05967 4.50016 4.33301C4.50016 4.60634 4.2735 4.83301 4.00016 4.83301Z" fill="#1F1F1F" />
          <path d="M6.66683 7.16667C5.10683 7.16667 3.8335 5.89333 3.8335 4.33333C3.8335 2.77333 5.10683 1.5 6.66683 1.5C8.22683 1.5 9.50016 2.77333 9.50016 4.33333C9.50016 5.89333 8.22683 7.16667 6.66683 7.16667ZM6.66683 2.5C5.6535 2.5 4.8335 3.32 4.8335 4.33333C4.8335 5.34667 5.6535 6.16667 6.66683 6.16667C7.68016 6.16667 8.50016 5.34667 8.50016 4.33333C8.50016 3.32 7.68016 2.5 6.66683 2.5Z" fill="#1F1F1F" />
          <path d="M14.6667 12.167H12C11.7267 12.167 11.5 11.9403 11.5 11.667C11.5 11.3937 11.7267 11.167 12 11.167H14.6667C14.94 11.167 15.1667 11.3937 15.1667 11.667C15.1667 11.9403 14.94 12.167 14.6667 12.167Z" fill="black" />
          <path d="M5.3335 12.167H1.3335C1.06016 12.167 0.833496 11.9403 0.833496 11.667C0.833496 11.3937 1.06016 11.167 1.3335 11.167H5.3335C5.60683 11.167 5.8335 11.3937 5.8335 11.667C5.8335 11.9403 5.60683 12.167 5.3335 12.167Z" fill="black" />
          <path d="M9.33333 14.4997C7.77333 14.4997 6.5 13.2263 6.5 11.6663C6.5 10.1063 7.77333 8.83301 9.33333 8.83301C10.8933 8.83301 12.1667 10.1063 12.1667 11.6663C12.1667 13.2263 10.8933 14.4997 9.33333 14.4997ZM9.33333 9.83301C8.32 9.83301 7.5 10.653 7.5 11.6663C7.5 12.6797 8.32 13.4997 9.33333 13.4997C10.3467 13.4997 11.1667 12.6797 11.1667 11.6663C11.1667 10.653 10.3467 9.83301 9.33333 9.83301Z" fill="black" />
        </svg>
      </button>

      <TableFilterPopup model={model} tabIndex={tabIndex} type={type} visibleOptionalFields={visibleOptionalFields} openTableFilter={openTableFilter} />
    </div>
  )
}

export default TableFilter
