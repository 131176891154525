import React, { Fragment } from 'react'
import useTableStore from '../../store/table'
import { X } from 'react-feather'
import FieldView from '../field/field-view'
import ModelService from '../../core/services/model-service/model-service'
import { Dialog, DialogPanel, Transition } from '@headlessui/react'

const ModalTableRow = ({ record, columns, rootField, funcRemoveLine, viewData, handleOnchange, resModel }) => {
    const { indexRowTableModal, setIndexRowTableModal, isUpdateTableModal, setIsUpdateTableModal } = useTableStore()

    const handleCancel = () => {
        setIndexRowTableModal(-1)
        if (!isUpdateTableModal) {
            funcRemoveLine(indexRowTableModal)
        }
        setIsUpdateTableModal(false)
    }

    const handleClose = () => {
        setIndexRowTableModal(-1)
        setIsUpdateTableModal(false)
    }

    return (
        <Transition
            show={true}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            as={Fragment}
        >
            <Dialog onClose={handleCancel}>
                <DialogPanel>
                    <div className='fixed bottom-0 left-0 right-0 top-0 z-[100]'>
                        <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                        <div className='absolute inset-0 flex flex-col justify-center items-center px-5'>
                            <div className='relative z-[1] max-w-full my-[88px] p-4 flex flex-col gap-4 w-[1000px] transform rounded-3xl bg-[#FFF] max-h-[90%] h-fit '>
                                <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                    <div id="modal-title" className='text-[20px] font-semibold'>
                                        {isUpdateTableModal ? "Cập nhật" : "Thêm"}
                                    </div>
                                    <button onClick={handleCancel} aria-label="Close">
                                        <X size={20} />
                                    </button>
                                </div>
                                <div className='flex flex-1 flex-col w-full overflow-auto relative'>
                                    <div className='w-full'>
                                        {columns?.map((col) => {
                                            return (
                                                <div
                                                    key={col.key}
                                                >
                                                    <FieldView
                                                        {...col.field}
                                                        rootField={rootField}
                                                        index={indexRowTableModal}
                                                        onchangeData={ModelService.toDataJS(record, viewData, resModel)}
                                                        string={col.title}
                                                        isForm={true}
                                                        handleOnchange={handleOnchange}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <button type='button' onClick={handleClose} className='button-primary'>{!isUpdateTableModal ? "Thêm" : "Cập nhật"}</button>
                                    <button type='button' onClick={handleCancel} className='button-secondary'>Hủy</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </Transition>

    )
}

export default ModalTableRow
