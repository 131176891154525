import React from 'react'
import { paymentType, stateType } from '../../constant/payment-type'
import { useTranslation } from 'react-i18next'
import { checkDomain } from '../../core/domain/domain'

const typeStyles = {
  primary: 'bg-blue-500 text-white',
  secondary: 'bg-lime-600 text-white',
  danger: 'bg-[#FDEDF0]  text-[#DF1C41]',
  info: 'bg-[rgba(5,152,237,0.12)] text-[rgb(5,152,237)]',
  success: 'bg-[rgba(46,181,83,0.1)] text-[rgb(46,181,83)]',
  warning: 'bg-[#FEF3EB] text-[#F17B2C]',
  default: 'bg-[#dee2e6]',
  light: 'bg-white text-gray-800 shadow-md',
}

const ButtonBadge = (props) => {
  const { t } = useTranslation()
  const { defaultValue, name, selection } = props
  const typeBadge = (name === "payment_state") ? paymentType : name === "state" ? stateType : null
  const decorationSuccess = props['decoration-success'] && checkDomain({ [name]: defaultValue }, props['decoration-success']);
  const decorationInfo = props['decoration-info'] && checkDomain({ [name]: defaultValue }, props['decoration-info']);
  const decorationDanger = props['decoration-danger'] && checkDomain({ [name]: defaultValue }, props['decoration-danger']);
  const decorationWarning = props['decoration-warning'] && checkDomain({ [name]: defaultValue }, props['decoration-warning']);

  const getType = () => {
    if (decorationInfo) {
      return typeStyles['info']
    }
    if (decorationSuccess) {
      return typeStyles['success']
    }
    if (decorationDanger) {
      return typeStyles['danger']
    }
    if (decorationWarning) {
      return typeStyles['warning']
    }
    return typeStyles['default']
  }
  const baseStyle =
    'rounded-md text-xs px-[0.82rem] py-1 text-center font-medium cursor-pointer transition duration-300 ease-in-out transform'
  const buttonClass = `${baseStyle} ${getType()}`
  const getLabel = (value) => {
    const item = selection?.find((item) => {
      if (item?.length > 0) {
        return item[0] === value
      }
    })
    return item[1]
  }


  return (<div className={buttonClass}>{t(getLabel(defaultValue))}</div>)
}

export default ButtonBadge
