export const RELATION_TYPE = {
  NO_CHANGE: 4,
}

export const METHOD_TYPE = {
  CREATE: 0,
  UPDATE: 1,
  DELETE: 2,
  UNLINK: 3
}
