import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useHandleField from '../model/main';
import { matchDomains } from '../../../core/domain/domain';

const GroupView = (props) => {
    const { fields, viewData, resModel, onchangeData, is_root_group, handleOnchange, string, setOnchangeData, invisible } = props
    const [isHidden, setHidden] = useState(false)
    useEffect(() => {
        setHidden(matchDomains(onchangeData, invisible))
    }, [])

    const { fieldList } = useHandleField({ fields: fields, viewData: viewData, resModel: resModel, onchangeData: onchangeData, handleOnchange: handleOnchange, setOnchangeData: setOnchangeData })

    return (
        <div className={`flex flex-col gap-3 ${is_root_group ? "grid col-span-2 grid-cols-2 gap-x-6 mt-4" : "col-span-2 md:col-span-1"} ${isHidden ? 'hidden' : ''}`}>
            {string && <div className='w-full border-b-2 border-[rgba(241,246,241,1)] flex items-center gap-2 text-center font-semibold text-sm text-[rgba(82,88,102,1)] py-[10px]'>
                <span className='rounded-full bg-primary size-[18px] text-[12px] text-white flex items-center justify-center'>&#105;</span>
                <span>{string}</span>
            </div>}
            <div className={`${is_root_group ? "grid col-span-2 grid-cols-2 gap-x-6" : "col-span-2 md:col-span-1"}`}>
                {fieldList}
            </div>
        </div>
    )
}

GroupView.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object),
    viewData: PropTypes.object,
    resModel: PropTypes.string,
    onchangeData: PropTypes.object,
    is_root_group: PropTypes.bool,
}

export default GroupView