import { create } from 'zustand'

const useToolBarStore = create((set) => ({
  name: '',
  resModel: '',
  breadCrumbs: [],
  setName: (newName) => set(() => ({ name: newName })),
  setResModel: (newResModel) => set(() => ({ resModel: newResModel })),
  setBreadCrumbs: (newItem) => set((state) => ({ breadCrumbs: [...state.breadCrumbs, newItem] })),
}))

export default useToolBarStore
