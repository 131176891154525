import React, { useState } from 'react'
import { Star } from 'react-feather'
import ModelService from '../../core/services/model-service/model-service'
import { useSearchParams } from 'react-router-dom'
import useHeaderStore from '../../store/header'
import { evalJSONContext, getSpecificationByFields } from '../../util/util'
import { Tooltip } from "react-tooltip"
import { Controller } from 'react-hook-form'

const RatingStar = ({ label, priorities = [], defaultValue, onSelectPriority, id }) => {
    const [rating, setRating] = useState(defaultValue);
    const [hover, setHover] = useState(0);

    const handleClick = (value) => {
        setRating(value);
        if (onSelectPriority) {
            onSelectPriority(value);
        }
    };

    const handleHover = (value) => {
        setHover(value)
    }

    return (
        <div className='flex gap-1 cursor-pointer w-fit' onMouseLeave={() => setHover(0)}>
            {Array.from({ length: priorities?.length }, (_, index) => {
                if (index === 0) return
                const starValue = index + 1;
                const fillColor = hover > 0 ? hover >= starValue ? '#f3cc00' : 'white' : rating >= starValue ? '#f3cc00' : 'white';
                const strokeColor = hover > 0 ? hover >= starValue ? '#f3cc00' : 'rgba(73,80,87,0.76)' : rating >= starValue ? '#f3cc00' : 'rgba(73,80,87,0.76)';
                return (
                    <>
                        <Star
                            key={starValue}
                            size={20}
                            strokeWidth={1.5}
                            fill={fillColor}
                            stroke={strokeColor}
                            onMouseEnter={() => handleHover(starValue)}
                            onClick={() => handleClick(starValue)}
                            data-tooltip-id={`${id}-${starValue}`}
                        />
                        <Tooltip className='z-50' id={`${id}-${starValue}`} content={`${label} : ${priorities[index]?.[1]}`} place='bottom-start' />
                    </>
                );
            })}
        </div>
    );
};

const Priority = (props) => {
    const { value, selection, isForm, name, methods, index: id } = props
    const [searchParams] = useSearchParams()
    const model = searchParams.get("model")
    const idForm = parseInt(searchParams.get("id"))
    const { rootContext } = useHeaderStore()

    const actionDataString = sessionStorage.getItem("actionData");
    const viewData = JSON.parse(sessionStorage.getItem("viewData"));
    const actionData = actionDataString && actionDataString !== "undefined" ? JSON.parse(actionDataString) : {};
    const _context = { ...evalJSONContext(actionData?.context) || {} }
    const contextObject = { ...rootContext, ..._context }
    const specification = viewData && getSpecificationByFields(isForm ? viewData?.views?.form?.fields : viewData?.views?.tree?.fields, {}, viewData, model)

    const defaultPriority = parseInt(value) + 1
    const label = viewData?.models?.[model]?.[name]?.string
    const idDefault = isForm ? idForm : id

    const savePriorities = async (priorityLevel) => {
        await ModelService.saveForm({
            ids: idDefault ? [idDefault] : [],
            resModel: model,
            data: { [name]: String(priorityLevel - 1) },
            specification: specification,
            context: contextObject,
        })
    }

    if (!isForm) {
        return (
            <RatingStar label={label} id={id} priorities={selection} defaultValue={defaultPriority} onSelectPriority={savePriorities} />
        )
    }

    return (
        <Controller
            name={name}
            control={methods.control}
            render={({ field: { value } }) => {
                const handlePriorityChange = (priorityLevel) => {
                    savePriorities(priorityLevel)
                }

                return (
                    <RatingStar
                        label={label}
                        id={id}
                        priorities={selection}
                        defaultValue={defaultPriority}
                        value={value || defaultPriority}
                        onSelectPriority={handlePriorityChange}
                    />
                )
            }}
        />
    )
}

export default Priority
