import React from 'react';
import { X } from 'react-feather';

const ModalLayer = ({ isOpen, onClose, title, children, }) => {
  return (
    isOpen && <div className="fixed bottom-0 left-0 right-0 top-0 z-[500]">
      <div className="absolute inset-0 bg-[rgba(27,27,27,0.48)]"></div>
      <div className="flex items-center justify-center mx-4 absolute inset-0 overflow-auto">
        <div className=" relative z-[1] mx-auto my-[88px] p-4 flex flex-col gap-2 max-w-[1000px] transform rounded-3xl bg-[#FFF]">
          <div className={`flex justify-between items-center border-[rgba(0,0,0,0.1)] pb-2`}>
            {title && (
              <div id="modal-title" className="text-[20px] font-semibold">
                {title}
              </div>
            )}
            <button onClick={onClose} aria-label="Close" className='ml-auto'>
              <X size={20} />
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalLayer;