import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useClickOutside } from '../../hooks/useClickOutside';

const StatusDropdown = ({ selection }) => {
  const colors = {
    normal: 'bg-gray-400',
    done: 'bg-primary',
    blocked: 'bg-red-500',
  };

  const titles = {
    normal: 'Đang thực hiện',
    done: 'Sẵn sàng',
    blocked: 'Đã bị chặn',
  };

  const [status, setStatus] = useState(colors.normal);
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useClickOutside(() => setIsOpen(false));

  const handleClick = (name) => {
    setIsOpen(!isOpen);
    setStatus(colors[name]);
  };

  return (
    <div ref={buttonRef} className="absolute top-7 right-6 z-10">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`transition-all rounded-full size-6 cursor-pointer ${status}`}
      />
      {isOpen && (
        <div className="absolute w-[200px] top-full bg-white transition-all right-0 flex flex-col rounded-lg z-10 shadow-md border">
          {selection.map((item, index) => {
            const isActive = status === colors[item[0]];
            return (
              <div
                key={index}
                className={`cursor-pointer relative hover:bg-gray-200 pl-[40px] pr-[20px] py-[5px] flex items-center gap-2 ${isActive ? 'bg-gray-100' : ''}`}
                onClick={() => handleClick(item[0])}
              >
                {isActive && (
                  <FaCheck className="absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 left-5" />
                )}
                <div className={`rounded-full size-4 ${colors[item[0]]}`} />
                {titles[item[0]]}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StatusDropdown;