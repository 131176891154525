import { evalPartialContext } from '../core/domain/context'
import { Domain, matchDomains } from '../core/domain/domain'
import { evaluateExpr } from '../core/domain/py'
import { COMPONENT_TYPE } from '../constant/componen-type'
import { FIELD_TYPE } from '../constant/field-type'
import alertify from 'alertifyjs'
import { METHOD_TYPE, RELATION_TYPE } from '../constant/method-type'
import { LocalStorageService } from './lib/LocalStorage'

export const getSpecification = (viewData, resModel) => {
  if (viewData?.models?.[resModel] && resModel) {
    const modelValues = Object.keys(viewData.models[resModel])
    if (modelValues.length > 0) {
      const spectification = modelValues.reduce((acc, item) => {
        acc[item] = {}
        return acc
      }, {})
      return spectification
    } else {
      console.warn('modelValues is empty')
    }
  } else {
    console.warn('listView.models[resModel] or model is undefined')
  }
}

export const getSpecificationByFields = (fields = [], specification = {}, viewData, model, modelRoot) => {
  if (Array.isArray(fields)) {
    let spec = { ...specification }
    fields.forEach((field) => {
      if (!field?.type_co || (field?.name && field?.type_co === COMPONENT_TYPE.FIELD)) {
        if (field?.type_tab && field?.type_tab !== 'tree') {
          const specTab = getSpecificationByFields(field?.fields, spec, viewData, model)
          spec = { ...spec, ...specTab }
        } else if (viewData?.models?.[model]?.[field?.name]) {
          if (viewData?.models?.[model]?.[field?.name]?.type === FIELD_TYPE.ONE2MANY || viewData?.models?.[model]?.[field?.name]?.type === FIELD_TYPE.MANY2MANY) {
            const relation = viewData?.models?.[model]?.[field?.name]?.relation
            const modelRelation = viewData?.models?.[relation]

            if (modelRelation) {
              spec[field?.name] = {
                fields: {},
              }

              if (modelRoot && modelRoot === relation) {
                spec[field?.name].fields = { id: {} }
              } else {
                spec[field?.name].fields = getSpecificationByFields(Object.values(modelRelation), {}, viewData, relation, model)
              }
            } else {
              spec[field?.name] = {
                fields: {
                  id: {},
                  display_name: {},
                }
              }
            }
          } else if (viewData?.models?.[model]?.[field?.name]?.type === FIELD_TYPE.MANY2ONE) {
            spec[field?.name] = { field: {} }
          } else {
            spec[field?.name] = {}
          }
        }
      } else if (field?.type_co === COMPONENT_TYPE.GROUP) {
        const specGroup = getSpecificationByFields(field?.fields, spec, viewData, model)
        spec = { ...spec, ...specGroup }
      } else if (field?.type_co === COMPONENT_TYPE.TREE) {
        const relation = viewData?.models?.[model]?.[field?.name]?.relation
        const specTreee = getSpecificationByFields(field?.fields, {}, viewData, relation, model)
        spec = { ...spec, [field?.name]: { fields: specTreee } }
      }
    })

    return spec
  } else {
    console.warn('fields is not array')
  }
}
export const formatCurrency = (amount, currency = 'USD') => {
  const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
  })

  return formatter.format(amount).replaceAll(".", ",")
}

export const formatFloatNumber = (floatNumber) => {
  const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'decimal',
    minimumFractionDigits: 0,
  })

  return formatter.format(floatNumber).replaceAll(".", ",")
}

export function formattedPath(path) {
  const formattedPath = path.replace('/', '').charAt(0).toUpperCase() + path.slice(2)
  const splitFormattedPath = formattedPath?.split('-')
  const showCapitalizedPart = splitFormattedPath[1] ? capitalizeFirstLetter(splitFormattedPath[1]) : ''
  return {
    showCapitalizedPart,
    splitFormattedPath,
  }
}

export function capitalizeFirstLetter(str) {
  if (!str) return str // Kiểm tra nếu chuỗi rỗng
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const convertStringToArray = (str) => {
  try {
    // Replace single quotes with double quotes and remove parentheses
    let formattedStr = str.replace(/'/g, '"').replace(/\(/g, '[').replace(/\)/g, ']');

    // Use a regular expression to find and replace variable names with their values
    formattedStr = formattedStr.replace(/"(\w+)"/g, (match, p1) => {
      if (typeof window[p1] !== 'undefined') {
        return JSON.stringify(window[p1]);
      }
      return match;
    });

    return JSON.parse(formattedStr);
  } catch (error) {
    console.error("Invalid string format", error);
    return [];
  }
};


export const getSessionStorage = (key) => {
  return sessionStorage.getItem(key) !== 'undefined' ? JSON.parse(sessionStorage.getItem(key)) : {};
}

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const parseInputString = (str) => {
  if (!str) return
  const items = str
    .slice(1, -1)
    .split(',')
    .map((item) => item.trim())
  return items.filter((item) => item).map((item) => item.replace(/[()'"]/g, ''))
}

export const clearBeforeToJson = (str) => {
  if (!str) return
  return str.replaceAll('"', "'")
}

export const convertDomainStringToArray = (str) => {
  const jsonString = str
    .replace(/\(/g, '[') // Replace "(" with "["
    .replace(/\)/g, ']') // Replace ")" with "]"
    .replace(/'/g, '"')
    .replace(/False/g, 'false')

  return jsonString
}

export const evalJSONContext = (_context, context = {}) => {
  try {
    return evalPartialContext(_context, context)
  } catch (err) {
    return null
  }
}

export const evalJSONDomain = (domain, context) => {
  try {
    if (context) {
      Object.keys(context)?.forEach((key) => {
        if (Array.isArray(context[key])) {
          const isTypeObject = context[key]?.every(item => typeof item === 'object' && item !== null && item?.id !== undefined)
          if (isTypeObject) {
            context[key] = context[key]?.map(item => item?.id)
          }
        }
      })
    }
    const value = evaluateExpr(domain, context)
    return value
  } catch (err) {
    try {
      const domainObject = new Domain(domain).toList(context)
      return domainObject
    } catch (err) {
      console.error(err)
      return []
    }
  }
}

export function convertStringEmptyToHyphen(str) {
  return str.replace(/\s+/g, '-').toLowerCase()
}

// Utility function to convert float to "HH:MM" format
export const convertFloatToTime = (floatValue) => {
  const hours = Math.floor(floatValue);
  const minutes = Math.round((floatValue - hours) * 60);
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};

export const convertTimeToFloat = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours + minutes / 60;
};

export const isNumber = (value) => {
  return !isNaN(value)
};

export const isFloat = (value) => {
  return !isNaN(value) && value.toString().indexOf('.') != -1;
};

export const createPathImage = (id, model, name) => {
  return `${process.env.REACT_APP_DOMAIN}web/image/${model}/${id}/${name}`
}

export const extractContent = (htmlString) => {
  const match = htmlString.match(/>([^<]*)</);
  return match ? match[1] : "";
}

export const toQueryString = (params) => {
  return Object.keys(params)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
    .join('&');
};

export const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    // Fallback for browsers that do not support navigator.clipboard
    const textArea = document.createElement('textarea')
    textArea.value = text
    textArea.style.position = 'fixed' // Avoid scrolling to the bottom of the page in MS Edge.
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      document.execCommand('copy')
    } finally {
      document.body.removeChild(textArea)
    }
  }
}
export const stringToColor = (name, id) => {
  const combined = name + id / 2;
  let hash = 0;

  for (let i = 0; i < combined.length; i++) {
    hash = combined.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert the hash into an RGB color code within a narrower medium range (120-180)
  const r = (hash >> 16) & 0xFF;
  const g = (hash >> 8) & 0xFF;
  const b = hash & 0xFF;

  // Constrain each RGB component to be between 120 and 180 for a more medium tone
  const adjustedR = 120 + (r % 61); // 120 to 180
  const adjustedG = 120 + (g % 61); // 120 to 180
  const adjustedB = 120 + (b % 61); // 120 to 180

  return `#${adjustedR.toString(16).padStart(2, "0")}${adjustedG.toString(16).padStart(2, "0")}${adjustedB.toString(16).padStart(2, "0")}`;
};

// format vnd price function by new Int
export const formatVNDPrice = (price) => {
  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(price)
}

export const showSuccessMessage = (message) => {
  alertify.set('notifier', 'position', 'top-right')
  alertify.success(message)
}

export const showErrorMessage = (message) => {
  alertify.set('notifier', 'position', 'top-right')
  alertify.error(message)
}

export const checkUniqueItemAppendArray = (array, newItem, conditionAttribute) => {
  const newArray = [...array]
  const index = newArray.findIndex(item => conditionAttribute ? item[conditionAttribute] === newItem[conditionAttribute] : item === newItem);

  if (index !== -1) {
    newArray.splice(index, 1);
    return newArray
  } else {
    newArray.push(newItem);
  }

  if (newArray.length === 0) {
    newArray.push(newItem);
  }

  return newArray
}
export const formatDateTime = (date, time) => {
  const hours = Math.floor(time);
  const minutes = (time % 1) * 60;
  return `${date}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
};

export const getArrayValues = (obj) => {
  const result = [];
  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      const updatedArray = obj[key].map(item => ({ ...item, key }));

      result.push(...updatedArray);
    }
  }
  return result;
};

export const getSubdomain = (url = window.location.href) => {
  const parts = url.split('.');
  if (parts.length > 2) {
    return parts[0].replace("https://", "").replace("http://", "");
  }
  return null;
};

// filter field change
export const filterFieldDirty = (id, viewData, formValues, dirtyFields, resModel, defaultData) => {
  const data = id ? { ...dirtyFields } : { ...formValues }
  for (const key in data) {
    if ((viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.ONE2MANY)) {
      const lineData = [];
      (formValues[key] ??= []).forEach((itemData, index) => {
        if (!itemData?.id) {
          lineData.push([METHOD_TYPE.CREATE, `virtual_${index}`, filterFieldDirty(
            itemData?.id,
            viewData,
            itemData,
            {},
            viewData?.models?.[resModel]?.[key]?.relation
          )])
        } else {
          dirtyFields[key].forEach((itemDirty) => {
            if (Object.values(itemDirty).includes(true)) {
              lineData.push([
                METHOD_TYPE.UPDATE,
                itemData?.id,
                filterFieldDirty(
                  itemData?.id,
                  viewData,
                  itemData,
                  itemDirty,
                  viewData?.models?.[resModel]?.[key]?.relation
                ),
              ])
            }
          })
        }
      });
      (defaultData[key] ??= []).forEach((item) => {
        if (!(formValues[key] ??= [])?.find((itemData) => itemData?.id === item?.id)) {
          lineData.push([METHOD_TYPE.DELETE, item?.id, item])
        }
      });

      data[key] = lineData
    } else if (viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.MANY2MANY) {
      const lineData = [];

      (formValues[key] ??= []).forEach((itemData, index) => {
        lineData.push([RELATION_TYPE.NO_CHANGE, itemData?.id])
      })
      data[key] = lineData
    }
    else if (id) {
      data[key] = formValues?.[key]
    }
  }

  return data
}

export function decimalToTime(decimal) {
  const hours = Math.floor(decimal);
  const minutes = Math.round((decimal - hours) * 60);
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export const removeUndefinedFields = (obj) => {
  const newObj = {};
  for (const key in obj) {
    if (obj[key]) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const downloadFile = async (url, filename) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadPDF = async (data, filename) => {
  const blob = await new Blob([data], { type: 'application/pdf' })
  const url = window.URL.createObjectURL(blob)
  downloadFile(url, filename)
}

export const isValidJSON = (str) => {
  try {
    return typeof str === 'string' && JSON.parse(str) !== undefined;
  } catch {
    return false;
  }
};


export const updateQueryParams = (key, value = null) => {
  const url = new URL(window.location.href);

  if (value === null) {
    url.searchParams.delete(key);
  } else {
    url.searchParams.set(key, value);
  }

  window.history.pushState({}, '', url);
};

export const groupNotices = (array) => {
  const grouped = array.reduce((result, item) => {
    const model = item.model;
    if (!result[model]) result[model] = {};

    item.notifications.forEach((notification) => {
      const notificationType = notification.notification_type;
      if (!result[model][notificationType]) {
        result[model][notificationType] = [];
      }
      result[model][notificationType].push(item);
    });
    return result;
  }, {});

  const flattened = Object.entries(grouped).flatMap(([model, notifications]) =>
    Object.entries(notifications).map(([notificationType, items]) => ({
      model,
      notificationType,
      resModelName: items[0].res_model_name,
      items,
    }))
  );

  return flattened.sort((a, b) => {
    const latestDateA = Math.max(...a.items.map(item => new Date(item.date).getTime()));
    const latestDateB = Math.max(...b.items.map(item => new Date(item.date).getTime()));
    return latestDateB - latestDateA;
  });
};

export const findLatestDate = (data) => {
  return data.reduce((latest, current) => {
    return new Date(current.date) > new Date(latest.date) ? current : latest;
  });
};

export const formatSortingString = (string) => {
  if (!string) return null
  return string
    .split(",")
    .map((field) => {
      let [key, order] = field.trim().split(/\s+/);
      order = order ? order.toUpperCase() : "ASC";
      return `${key} ${order}`;
    })
    .join(", ");
};

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
}

export const appendNewAttribute = (object, newObject) => {
  return object.map(item => {
    let updatedItem = { ...item };
    if (updatedItem.type_co === "field") {
      updatedItem = { ...updatedItem, ...newObject }
    }

    if (Array.isArray(updatedItem.fields)) {
      updatedItem.fields = appendNewAttribute(updatedItem.fields, newObject);
    }

    return updatedItem;
  });
}

export const getFieldsOnChange = (fields) => {
  let result = [];

  function traverse(items) {
    for (const item of items) {
      if (item.type_co === "field" && matchDomains(fields, item.on_change)) {
        result.push(item.name);
      }
      if (item.fields && Array.isArray(item.fields)) {
        traverse(item.fields);
      }
    }
  }

  traverse(fields);
  return result;
}

export const mergeObjects = (object1, object2) => {
  // Kết hợp các trường từ object2 vào mergedObject
  const mergedObject = { ...object2 };

  // Lặp qua tất cả các khóa trong object1
  Object.keys(object1).forEach((key) => {
    // Nếu key tồn tại trong object2 và là một mảng, gộp các phần tử của mảng
    if (Array.isArray(object1[key]) && Array.isArray(object2[key])) {
      mergedObject[key] = object2[key].map((item, index) => {
        // Nếu phần tử tại index này trong object1 có, gộp nó với item trong object2
        if (object1[key][index]) {
          return {
            ...item, // Giữ lại các trường trong item của object2
            ...object1[key][index], // Thay thế với các trường của item trong object1
          };
        }
        return item; // Nếu không có, giữ nguyên item trong object2
      });
    } else if (typeof object1[key] === 'object' && typeof object2[key] === 'object') {
      // Nếu cả hai trường là đối tượng, thực hiện đệ quy
      mergedObject[key] = mergeObjects(object1[key], object2[key]);
    } else {
      // Nếu trường chỉ là một giá trị đơn giản, thay thế nếu có trong object1
      mergedObject[key] = object1[key] !== undefined ? object1[key] : object2[key];
    }
  });

  // Thêm các trường còn lại từ object2 vào mergedObject
  Object.keys(object2).forEach((key) => {
    if (!mergedObject.hasOwnProperty(key)) {
      mergedObject[key] = object2[key];
    }
  });

  return mergedObject;
};


export const hasAccessToken = () => {
  const token = LocalStorageService.getAccessToken()
  return !!token
}

export const checkIfHtmlHasContent = (htmlString) => {
  // Strip tags and check if there's meaningful text
  const textOnly = htmlString.replace(/<[^>]*>/g, "").trim();
  if (textOnly.length > 0) {
    return true; // Contains meaningful text
  }

  // Check if there are non-empty tags
  const nonEmptyTagRegex = /<([^>]+)>(.*?)<\/\1>/; // Matches tags with content between them
  return nonEmptyTagRegex.test(htmlString.trim());
};