import { matchDomains } from "../../../core/domain/domain";
import FieldView from "../../field/field-view";
import GroupView from "../view/group-view";
import TreeViewForm from "../view/tree-view-form";

const useHandleField = ({ fields, viewData, resModel, onchangeData = {}, handleOnchange, setOnchangeData, tabIndex = -1 }) => {
    const fieldList = (fields ??= [])?.map((field, index) => {
        if (field?.type_co === "field") {
            const fieldDataRoot = viewData?.models?.[resModel]?.[field?.name] || {};
            const mergedField = { ...fieldDataRoot, onchangeData: onchangeData, setOnchangeData: setOnchangeData, ...field };

            return <FieldView handleOnchange={handleOnchange} key={index} {...mergedField} isForm={true} />
        }
        else if (field?.type_co === "group") {
            const mergeProps = { viewData: viewData, resModel: resModel, onchangeData: onchangeData, setOnchangeData: setOnchangeData, ...field }
            return (<GroupView handleOnchange={handleOnchange} key={index} {...mergeProps} />)
        }
        else if (field?.type_co === "tree") {
            const invisible = matchDomains(onchangeData, field?.invisible)
            if (invisible) return
            const fieldDataRoot = viewData?.models?.[resModel]?.[field?.name] || {};
            const mergedField = { ...fieldDataRoot, onchangeData: onchangeData, ...field };

            return <div className="col-span-2">
                <div className="w-full overflow-x-auto overflow-y-hidden mb-4">
                    {tabIndex < 0 ? <div className='text-sm font-medium text-dark mb-2'>{mergedField?.string}</div> : null}
                    <TreeViewForm handleOnchange={handleOnchange} onchangeData={onchangeData} tab={{ ...mergedField, index: tabIndex, modelTab: viewData?.models?.[resModel]?.[mergedField?.name] }} viewData={viewData} resModel={resModel} key={index} setOnchangeData={setOnchangeData} />
                </div>
            </div>
        }
        else {
            return <></>
        }
    });

    return {
        fieldList,
    }
};

export default useHandleField