import moment from "moment"
import { useFormatDate } from "../../hooks/useFormatDate"
import { formatCurrency, showErrorMessage, showSuccessMessage } from "../../util/util"
import { FaRegTrashAlt } from "react-icons/fa"
import { useMutation } from "@tanstack/react-query"
import ChatterAction from "../../core/services/chatter"
import { RenderSelectFiles } from "./SelectFiles"
import { useTranslation } from "react-i18next"
import { Avatar } from "../../components/widget/Info"

const MessageItem = ({ id, author, date, oldValue, newValue, status, body, isNote, listFiles, handleAfterSubmit }) => {
    const { getRelativeTime } = useFormatDate()
    const { t } = useTranslation()

    const formatValue = (value) => {
        if (typeof value === "number") {
            return formatCurrency(parseFloat(value), "VND")
        }

        if (moment(value).isValid()) {
            return moment(value).format("DD/MM/YYYY")
        }

        if (typeof value === "boolean") {
            return value ? "Có" : "Không"
        }

        return value ? value : "Không"
    }

    const { mutateAsync: handleDelete, isPending: loadingCallButton } = useMutation({
        mutationFn: () => ChatterAction.deleteComment({
            message_id: id
        }),
        onSuccess: (data) => {
            handleAfterSubmit && handleAfterSubmit()
            showSuccessMessage(`${t('send_note_success')}`);
        },
        onError: () => {
            showErrorMessage(`${t('end_note_failure')}`);
        }
    });

    return (
        <div className="flex items-start gap-[8px] group">
            <div className=" rounded-full overflow-hidden">
                {/* <img src="/assets/img/avatar/admin.jpg" alt="avatar" className="object-cover" /> */}
                <Avatar showDetail={false} fullName={author?.name} id={author?.userId} size={30} />
            </div>
            <div className="flex flex-col gap-[4px] flex-1">
                <div className=" flex items-center gap-[4px] text-[14px] leading-[14px]">
                    <div className="font-bold text-[#333]">{author?.name}</div>
                    <div className="text-sm text-[rgba(73,80,87,0.76)] opacity-75"> - {getRelativeTime(moment(date).format("DD-MM-YYYY HH:mm:ss"))}</div>
                    <FaRegTrashAlt
                        size={15}
                        color='black'
                        onClick={handleDelete}
                        className={`ml-4 cursor-pointer transition-all group-hover:block hidden ${loadingCallButton ? 'cursor-not-allowed' : ''}`}
                    />
                </div>
                {body && <div className={`text-[#495057c2] flex items-center font-medium w-fit ${!isNote ? 'rounded-lg p-3 bg-[#DEEFE4]' : ''}`} dangerouslySetInnerHTML={{ __html: body }}></div>}

                {status && <div className=" gap-[4px] text-[14px] flex items-center flex-wrap">
                    <span className="w-[24px] h-[21px] flex justify-center items-center">
                        <div className="w-[4px] bg-[#333] h-[4px] rounded-full"></div>
                    </span>

                    <span className="text-[#495057c2] font-medium">{formatValue(oldValue)}</span>

                    <img src="/assets/img/chatter/right-arrow.svg" alt="attachment" className="object-cover w-[14px] h-[14px]" />

                    <span className="text-[#0180A5] font-medium">{formatValue(newValue)}</span>

                    <span className="font-normal italic text-[#495057c2]">({status})</span>
                </div>}

                {listFiles?.length > 0 && (
                    <div className="flex items-center gap-2">
                        <RenderSelectFiles selectedFiles={listFiles} />
                    </div>
                )}
            </div>
        </div>
    )
}
export default MessageItem