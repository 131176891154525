import React from 'react'
import { X } from 'react-feather'
import useSearchStore from '../../../../store/search'
import useSearch from '../../model/search'
import { useTranslation } from 'react-i18next'
import { SEARCH_TYPE } from '../../../../constant/search-type'

const TagSearch = () => {
    const { selectedTags, filterBy, setFilterBy, groupBy, setGroupBy, setGroupByDomain, setSelectedTags } = useSearchStore()
    const { removeSelectedTag } = useSearch()
    const { t } = useTranslation()
    return (
        selectedTags?.map((tag, index) => (
            tag?.values?.length > 0 &&
            <div key={'selected-tag-' + index} className='flex min-h-full overflow-hidden rounded bg-[#E9ECEF]'>
                <div className='tag-title flex items-center justify-center px-2 text-sm font-semibold leading-[1.5] text-white'>
                    {tag?.title}
                </div>
                <div className='pl-2 align-middle text-[#495057]'>
                    {tag.values.map((value, idx) => (
                        <React.Fragment key={idx}>
                            <span>{value}</span>
                            {idx < tag.values.length - 1 && <span className='text-sm italic text-[#495057] text-opacity-50'> {tag?.type === "group_by" ? "> " : t("or")} </span>}
                        </React.Fragment>
                    ))}
                </div>
                <button className='px-2 text-sm font-bold' onClick={() => {
                    removeSelectedTag(tag?.name)
                    if (tag?.type === SEARCH_TYPE.FILTER_BY) {
                        setFilterBy(filterBy.map(item => ({ ...item, active: false })))
                    } else if (tag?.type === SEARCH_TYPE.GROUP_BY) {
                        setGroupBy(groupBy.map(item => ({ ...item, active: false })))
                        setGroupByDomain(null)
                        setSelectedTags([...selectedTags.filter(item => item.type !== SEARCH_TYPE.GROUP_BY)])
                    }
                }}>
                    <X size={16} />
                </button>
            </div>
        ))
    )
}

export default TagSearch
