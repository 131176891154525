import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ImageWithBasePath from '../general/ImageCustom'
import useExcelStore from '../../store/excel'
import UploadFiles from '../../pages/upload-files/page'
import ExcelService from '../../core/services/import-excel'
import UploadButton from '../tree/UploadButton'

const Excel = () => {
    const url_icon = JSON.parse(sessionStorage.getItem("url_icon")) ?? {}
    const parent_icon = JSON.parse(sessionStorage.getItem("parent_icon")) ?? {}
    const title = sessionStorage.getItem("title") ?? ""
    const vid = sessionStorage.getItem("vid") ?? ""
    const [searchParams] = useSearchParams()
    const model = searchParams.get("model")
    const parseModel = model.replace(/\./g, '_');
    const { t } = useTranslation()
    const existIcon = url_icon?.url_icon ? url_icon : parent_icon
    const navigate = useNavigate()
    const { dataParse, setDataParse, isFileLoaded } = useExcelStore()
    const [data, setData] = useState([])
    const template = data?.length > 0 ? data[0]?.template : ''

    useEffect(() => {
        const fetchData = () => {
            setDataParse(null)
            ExcelService.GetFileExcel({ model }).then((res) => {
                setData(res)
            }).catch((err) => console.log(err)
            )
        }
        fetchData()

    }, [])

    return (
        <>
            {dataParse && isFileLoaded ? <UploadFiles title={title} /> : <div className='page-wrapper'>
                <div className='my-[32px] flex justify-between items-center'>
                    <div className='flex gap-4 '>
                        <div className="icon p-2 border border-[#E2E4E9] rounded-full flex justify-center items-center bg-primary w-12 h-12">
                            <img
                                src={existIcon?.url_icon && existIcon?.url_icon?.startsWith("http") ? existIcon.url_icon : process.env.REACT_APP_DOMAIN + existIcon.url_icon}
                                width={20}
                                height={20}
                                alt="img"
                            />
                        </div>
                        <div>
                            <div className='title text-xl tracking-wide text-dark font-semibold'>{title?.toUpperCase()}</div>
                            <div className='text-[20px] font-normal text-[#16A730]'>{t('upload-excel')}</div>
                        </div>
                    </div>
                    <div className='flex gap-[16px]'>
                        <button type='button' onClick={() => {
                            navigate(`/list?vid=${vid}&model=${model}`)
                        }} style={{
                            boxShadow: "0px 1px 2px 0px rgba(233,53,53,0.08)"
                        }} className='rounded-[10px] text-[14px] border-[1px] border-solid border-[#DF1C41] bg-[#fff] p-[10px] font-medium text-[#DF1C41]'>
                            {t('cancel')}
                        </button>
                        {/* <UploadButton /> */}
                    </div>
                </div>
                <div style={{
                    boxShadow: "0px 1px 8px 0px rgba(82,88,102,0.06)"
                }} className='border-[1px] border-solid border-[#F2F2F2] py-[157px] px-[313px] bg-[#fff] rounded-[10px]'>
                    <div className='bg-[#FFF] w-full max-w-[500px] mx-auto items-center flex flex-col gap-[24px] border-[2px] border-dashed border-[#DEE7DD] rounded-xl p-[32px]'>
                        <div className='border-[10px] flex justify-center items-center w-[90px] h-[90px] border-solid border-[#F1F6F1] bg-[#F1F6F1] rounded-full'>
                            {/* <ImageWithBasePath width={40} height={40} src={'assets/img/folder-upload.svg'}
                                alt={'img'}
                            /> */}
                            <UploadButton />
                        </div>
                        <div className='flex flex-col gap-1 items-center'>
                            <div className='text-[#0E121B] text-[18px] font-medium'>
                                {t('excel-1')}
                            </div>
                            <div className='text-[16px] text-center text-[#A3A3A3] font-normal'>
                                {t('excel-2')}
                            </div>
                        </div>
                        {data?.length > 0 && <a
                            href={`https://saas.vitrust.app${template}`}
                            style={{
                                boxShadow: "0px 1px 2px 0px rgba(55,93,251,0.08)"
                            }}
                            className='cursor-pointer flex gap-2 items-center border-[1px] border-solid border-[#11A52B] p-[10px] rounded-[10px] bg-[#fff]'
                        >
                            <ImageWithBasePath
                                width={20}
                                height={20}
                                src={'assets/img/download.svg'}
                                alt={'img'}
                            />
                            <div className='text-[14px] font-medium text-[#0B701D]'>
                                {t('excel-7')}
                            </div>
                        </a>}
                        <div className='text-[14px] font-normal text-[#A3A3A3]'>
                            {t('excel-3')} <span className='text-[14px] font-normal text-[#008C4F] underline'>
                                {t('excel-4')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Excel