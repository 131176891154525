import { callPath, uploadFile } from '../../../constant/endpoint'
import { CookieStorage } from '../../../util/lib/Cookies'
import requests from '../AxiosClient'

const ExcelService = {
  uploadFile: async ({ modal, files }) => {
    return requests.post(`${uploadFile}?model=${modal}`, files, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  uploadIdFile: async ({ modal, files, id }) => {
    return requests.post(`${uploadFile}?model=${modal}&id=${id}`, files, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  ParsePreview: async ({ id, selectedSheet, isHeader }) => {
    const language = CookieStorage.getLanguage()
    const formatLanguage = language === "vi" ? 'vi_VN' : 'en_US'
    const jsonData = {
      model: 'base_import.import',
      method: 'parse_preview',
      ids: [id],
      kwargs: {
        options: {
          import_skip_records: [],
          import_set_empty_fields: [],
          fallback_values: {},
          name_create_enabled_fields: {},
          encoding: '',
          separator: '',
          quoting: '"',
          date_format: '',
          datetime_format: '',
          float_thousand_separator: ',',
          float_decimal_separator: '.',
          advanced: true,
          has_headers: isHeader,
          keep_matches: false,
          limit: 2000,
          sheets: [],
          sheet: selectedSheet,
          skip: 0,
          tracking_disable: true,
        },
      },
      with_context: {
        lang: formatLanguage,
        tz: "Asia/Saigon"
      }

    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  ExecuteImport: async ({ columns, fields, idFile, options, dryrun }) => {
    const jsonData = {
      model: 'base_import.import',
      method: 'execute_import',
      ids: [idFile],
      kwargs: {
        fields: fields,
        columns: columns,
        options: options,
        dryrun: dryrun,
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  GetFileExcel: async ({ model }) => {
    const jsonData = {

      "model": model,

      "method": "get_import_templates",

      "args": []

    }
    return requests.post(callPath, jsonData)
  }
}
export default ExcelService
