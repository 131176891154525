import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileAlt, FaRegTrashAlt } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import ChatterAction from '../../core/services/chatter';
import { showErrorMessage } from '../../util/util';
import { LuLoader } from "react-icons/lu";

export const ButtonSelectFiles = ({ fileInputRef, selectedFiles, setSelectedFiles, }) => {
  const [searchParams] = useSearchParams()
  const resModel = searchParams.get('model')
  const id = searchParams.get('id')
  const { t } = useTranslation();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (data) => ChatterAction.upload(data),
    onSuccess: (data) => {
      console.log('Upload file success:', data)
      const formatData = data?.data?.['ir.attachment']?.[0]
      setSelectedFiles(prev => [...prev, formatData]);
    },
    onError: () => {
      showErrorMessage(`${t('thất bại')}`);
    }
  });

  const handleOnChange = async (event) => {
    const formData = new FormData();
    const files = Array.from(event.target.files);
    event.target.value = '';

    if (files.length > 0) {
      console.log('Selected files:', files);

      formData.append('ufile', files[0]);
      formData.append('thread_model', resModel);
      formData.append('thread_id', Number(id));
      formData.append('is_pending', true);

      await mutateAsync(formData);
    }
  };

  return (
    <>
      {isPending ? <>
        <LuLoader className='animate-spin' />
        <span className="text-sm text-gray-500">{t('Đang tải lên...')}</span>
      </> : (
        <button
          type='button'
          disabled={isPending}
          className={`w-[22px] h-[24px] rounded-full overflow-hidden transition-all ${isPending ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={() => fileInputRef.current.click()}
        >
          <img src="/assets/img/chatter/attachment.svg" alt="attachment" className="object-cover" />
        </button>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleOnChange}
        multiple
      />
    </>
  )
}

const FileItem = ({ filename, index, handleRemoveFile, props }) => {
  console.log('props:', props);
  return (
    <div
      key={index}
      className="px-4 py-2 flex relative items-center overflow-hidden justify-between group gap-2 bg-gray-200 rounded-md border-r-[1px] border-primary w-fit"
    >
      <FaFileAlt size={30} color="bg-primary" />
      <p>{filename}</p>
      {handleRemoveFile && <div className="h-full flex items-center justify-center bg-red-400 px-2 cursor-pointer absolute -right-4 translate-x-5 group-hover:-translate-x-4 transition-all">
        <FaRegTrashAlt
          size={20}
          color='white'
          onClick={() => handleRemoveFile(index)}
        />
      </div>}
    </div>
  )
}

const ImageItem = ({ id, filename, checksum, index, handleRemoveFile }) => {
  const { data: image } = useQuery({
    queryKey: [`get-image-${''}`,],
    queryFn: () => ChatterAction.getImage({ filename, checksum }),
    refetchOnWindowFocus: false,
  })
  // const image = `${process.env.REACT_APP_DOMAIN}web/image?db=${process.env.REACT_APP_DB}?filename=${filename}&unique=${checksum}&width=1920&height=300`
  return (
    <div
      key={index}
      className="relative group"
    >
      {image && <img src={image} alt={filename} className="w-32 h-32 object-cover rounded-md" />}
      {handleRemoveFile && <FaRegTrashAlt
        size={20}
        color='white'
        onClick={() => handleRemoveFile(index)}
        className='absolute top-2 right-2 cursor-pointer group-hover:block hidden'
      />}
    </div>
  )
}

export const RenderSelectFiles = ({ selectedFiles, setSelectedFiles }) => {
  const handleRemoveFile = (index) => {
    setSelectedFiles && setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const types = {
    "image/jpeg": ImageItem,
    "image/png": ImageItem,
    "video/mp4": ImageItem,
    "video/quicktime": ImageItem,
    "video/webm": ImageItem,
    default: FileItem,
  }
  console.log('selectedFiles:', selectedFiles);

  return (
    <div className="mt-3 flex flex-wrap w-full gap-2">
      {selectedFiles.map((file, index) => {
        if(file?.mimetype?.includes("image") || file?.inCludes("img")) {
          return <img className='max-h-[300px] max-w-[300px]' src={file?.datas} alt={file?.name} />
        }
        const Component = types[file?.mimetype] || types.default;
        return <Component key={index} {...file} index={index} handleRemoveFile={setSelectedFiles && handleRemoveFile} />
      })}
    </div>
  )
}


