import React from 'react';
import { Controller } from 'react-hook-form';

const CharField = ({
  name,
  value,
  readonly,
  placeholder,
  required,
  invisible,
  methods,
  isForm,
  defaultValue,
  handleOnchange,
}) => {
  if (!isForm) {
    return <span>{value || defaultValue}</span>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={{
        required: required && !invisible,
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <input
          value={value}
          onBlur={(e) => {
            if (readonly || e.target.value === value) return;
            onBlur();
            handleOnchange(name, e.target.value);
          }}
          onChange={(e) => {
            if (!readonly) {
              onChange(e.target.value);
            }
          }}
          type="text"
          readOnly={readonly}
          disabled={readonly}
          placeholder={placeholder}
          required={!invisible && required}
          style={invisible ? { display: 'none' } : {}}
          className={` ${!isForm ? '!bg-transparent focus-within:!border-none' : 'field'
            }`}
        />
      )}
    />
  );
};

export default CharField;
