import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionService from '../../core/services/actions';
import { evalJSONContext, isObjectEmpty, showErrorMessage } from '../../util/util';
import useHeaderStore from '../../store/header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FieldView from '../field/field-view';
import { matchDomains } from '../../core/domain/domain';
import { useFormContext } from 'react-hook-form';
import useToolBarStore from '../../store/toolbar';

const BoxButton = ({ listButtons, model, formValues, actionData, handleOnchange }) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const id = searchParams.get("id")
    const vid = searchParams.get("vid")
    const { t } = useTranslation();
    const { rootContext } = useHeaderStore()
    const context = evalJSONContext(actionData?.context)
    const methods = useFormContext()
    const [buttons, setButtons] = useState([])
    const { breadCrumbs, setBreadCrumbs } = useToolBarStore()

    const { mutate: mutateCallButton } = useMutation({
        mutationFn: (item) => {
            if (item.type === "action") {
                return ActionService.loadAction({
                    idAction: item?.name,
                    idDetail: parseInt(id),
                    model: model
                })
            } else {
                return ActionService.callButton({
                    method: item?.name,
                    resModel: model,
                    ids: formValues?.id ? formValues?.id : [],
                    context: { ...rootContext, ...context },
                });
            }
        },
        onSuccess: (data, variables) => {
            if (!data && !data?.result && !data?.data) {
                const { field } = variables
                methods.setValue(field?.name, formValues?.[field.name])
                return
            }

            const result = data?.result ?? data
            const { view_mode, res_model, views, res_id } = result
            const viewsList = views ?? view_mode?.split(",").map(view => [false, view])

            sessionStorage.setItem("actionData", JSON.stringify({ ...result, views: viewsList }))

            if (viewsList[0][1] === "tree" || viewsList[0][1] === "list") {
                setBreadCrumbs({ view: "list", vid: vid, model: model, id: id })
                navigate(`/list?model=${res_model}${variables?.type === "action" ? `&active_id=${id}` : ""}`)
            } else {
                setBreadCrumbs({ view: viewsList[0][1], vid: vid, model: model, id: id })
                navigate(`/${viewsList[0][1]}?model=${res_model}${res_id ? `&id=${res_id}` : ""}`)
            }
        },
        onError: () => {
            showErrorMessage(`${item?.string} ${t('thất bại')}`);
        }
    });

    const handleAction = (item) => {
        if (item?.field?.widget === "website_redirect_button" || item?.widget === "website_redirect_button") {
            navigate(`/elearning?id=${id}`)
            return;
        }
        mutateCallButton(item);
    }

    useEffect(() => {
        if (!isObjectEmpty(formValues)) {
            const buttonBoxVisibles = listButtons?.filter((button) => {
                if (!matchDomains(formValues, button?.invisible)) return button
            })
            setButtons(buttonBoxVisibles)
        }
    }, [formValues])

    return (
        buttons?.length > 0 &&
        <div className="bg-white border-1 border-[rgba(222,231,221,1)] rounded-[10px] !p-4">
            <div className='mx-auto w-fit'>
                <div className='flex items-center justify-center flex-wrap gap-[10px] w-full'>
                    {buttons?.map((item, index) => {
                        return (
                            <button type='button' onClick={() => handleAction(item)} key={index} className="flex items-center gap-2 flex-shrink-0 border-1 border-[rgba(222,231,221,1)] shadow-[0px_1px_8px_rgba(82,88,102,0.06)] rounded-lg cursor-pointer p-2 hover:bg-[#f9f9f9]">
                                <span className='border-1 border-[rgba(222,231,221,1)] rounded-lg bg-[#F7F7F7] size-8 flex items-center justify-center'>
                                    <i className={`fa ${item?.field?.widget === 'website_redirect_button' ? "fw fa-globe o_button_icon fa-fw" : item?.icon} ${item?.class} text-secondary size-4 flex items-center justify-center`}></i>
                                </span>
                                <div
                                    className='flex flex-col items-start' >
                                    <span className='text-[12px]'>
                                        {item?.field?.widget === 'website_redirect_button'
                                            ? "Đi đến"
                                            : (item?.field?.readonly || !item?.field?.name ? item?.field?.string : "")
                                        }
                                    </span>
                                    <span className='text-sm font-semibold text-primary'>
                                        <FieldView
                                            {...item?.field}
                                            onchangeData={formValues}
                                            string={!item?.field?.readonly ? "" : item?.field?.string}
                                            isForm={!item?.field?.readonly}
                                            widget={item?.field?.widget ?? item?.widget}
                                            handleOnchange={handleOnchange}
                                        />
                                    </span>
                                </div>
                            </button>
                        )
                    })}
                </div>
            </div>
        </div>

    )
}

export default BoxButton