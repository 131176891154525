import React from 'react';
import ModalLayer from './ModalLayer';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ViewServices from '../../core/services/view-service/view-service';
import useHandleField from '../form/model/main';
import { useSearchParams } from 'react-router-dom';
import ModelService from '../../core/services/model-service/model-service';
import useFormStore from '../../store/form';
import { showErrorMessage, showSuccessMessage } from '../../util/util';
import { useTranslation } from 'react-i18next';

const ModalCalendar = ({ id, name, fields, relationMappingData = {}, initialMappingData = {}, rootContext, resModel, setIsOpenModal, isOpenModal, quickCreateViewId, handleOnchange, onchangeData }) => {
  const { t } = useTranslation()
  const { formSubmitComponent } = useFormStore()
  const [searchParams] = useSearchParams()
  const vid = parseInt(searchParams.get("vid"))
  const modelParams = searchParams.get("model")

  const { data: viewData } = useQuery({
    queryKey: [`view-${quickCreateViewId}`,],
    queryFn: () => ViewServices.getFieldView({
      resModel: resModel,
      views: [[Number(quickCreateViewId), 'form']],
      context: rootContext,
      id: vid
    }),
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })

  const { fieldList } = useHandleField({ fields: fields, viewData: viewData, resModel: resModel, onchangeData: ModelService.toDataJS({ ...onchangeData, ...relationMappingData, ...initialMappingData }, viewData, resModel), handleOnchange: handleOnchange })

  const handleClose = () => {
    setIsOpenModal(false);
  };


  const submitForm = async (event) => {
    event.preventDefault();
    if (formSubmitComponent?.[modelParams]) {
      await formSubmitComponent?.[modelParams]?.current?.dispatchEvent(new Event('submit', { cancelable: true, }))
      handleClose()
    }
  }

  const handleFormSubmit = async () => {
    try {
      setLoading(true)
      const data = ModelService.parseORMOdoo(
        filterFieldDirty(id, viewData, formValues, dirtyFields, resModel, onchangeData)
      )

      const response = await ModelService.saveForm({
        ids: id ? [id] : [],
        resModel: resModel,
        data: data,
        specification: specification,
        context: contextAction,
      })
      if (response && response.length > 0 && isMainForm) {
        searchParams.set('id', response?.[0]?.id)
        if (isInModel) {
          refetchDataForm()
        } else {
          const _url = `/${VIEW_TYPE.FORM}?${searchParams.toString()}`
          navigate(_url)
        }
      }
      setLoading(false)
      showSuccessMessage(!id ? `${t('create_success')}` : `${t('update_success')}`)
    } catch (error) {
      console.log(error)
      setLoading(false)
      showErrorMessage(!id ? `${t('create_fail')}` : `${t('update_fail')}`)
    }
  }

  return (
    <ModalLayer isOpen={isOpenModal} onClose={handleClose} title='Sự kiện mới' >
      <div className={`min-w-[350px] min-h-[300px] pb-12`}>
        {/* <Form
          className={'md:grid-cols-4'}
          relationMappingData={relationMappingData}
          initialMappingData={initialMappingData}
          isNoteBookViewMode={true}
          resModel={resModel}
          actionData={{}}
          viewData={viewData}
          id={id}
          vid={vid}
          isMainForm={false}
          isDisplayBreadcrumbs={false}
          parentHandleOnChange={handleOnchange}
        /> */}
        <div className="grid grid-cols-4 gap-x-4 lg:gap-x-8">
          {fieldList}
        </div>
        <div className="flex gap-3 right-6 justify-end py-2 fixed bottom-4">
          <button
            type='button'
            onClick={submitForm}
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-gray-600 transition duration-300"
          >
            Lưu
          </button>
          <button
            onClick={handleClose}
            className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-300"
          >
            Đóng
          </button>
        </div>
      </div>
    </ModalLayer>
  );
};

export default ModalCalendar;