import { useEffect, useState } from "react";
import { matchDomains } from "../../../core/domain/domain";
import useHeaderStore from "../../../store/header";
import { evalJSONContext, removeUndefinedFields, showErrorMessage, showSuccessMessage } from "../../../util/util";
import ModalDynamic from "../../modal/ModalDynamic";
import useActionHandler from "../../../hooks/useActionButton";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import ActionService from "../../../core/services/actions";
import ModelService from "../../../core/services/model-service/model-service";
import ModalLayer from "../../modal/ModalLayer";

const ButtonView = ({ action, context, resModel, dataForm, refetch, handleOnChange, formSpecification, isButtonInside = false, handleCloseActions }) => {
  const { t } = useTranslation();
  const { rootContext } = useHeaderStore();
  const contextAction = action?.context && evalJSONContext(action?.context, context);
  const [isHidden, setHidden] = useState(false);
  const [isOpenSaveConfirm, setOpenSaveConfirm] = useState(false);

  const { loading, resultLoadAction, setModalAction, handleButtonAction, modalAction } = useActionHandler({
    context,
    resModel,
    dataForm,
    refetch,
  });

  const handleConfirm = async () => {
    refetch && refetch();
    return showSuccessMessage(`${action?.string} ${t('thành công')}`);
  }

  const handleResultButtonOutside = (data) => {
    if (action?.name === 'button_cancel') {
      return handleConfirm();
    } else if (Object.prototype.hasOwnProperty.call(data, 'data') && !data?.data) {
      return setOpenSaveConfirm(true)
    }
    refetch && refetch();
    resultLoadAction[data?.type]({ actionId: dataForm?.id, actionResult: data, formValues: context, url: data?.url });
  }

  const handleResultButtonInside = (data) => {
    handleCloseActions()
    showSuccessMessage(`${action?.string} ${t('thành công')}`);
  }

  const { mutateAsync: callButtonMutate, isPending: loadingCallButton } = useMutation({
    mutationFn: ({ method, resModel, ids, context }) => ActionService.callButton({
      method: method,
      resModel: resModel,
      ids: ids,
      context: context
    }),
    onSuccess: (data) => {
      if (isButtonInside) {
        handleResultButtonInside(data);
      } else {
        handleResultButtonOutside(data);
      }
    },
    onError: () => {
      showErrorMessage(`${action?.string} ${t('thất bại')}`);
    }
  });

  const { mutateAsync: webSaveMutate, isPending: loadingWebSave } = useMutation({
    mutationFn: ({ resModel, ids, data }) => ModelService.saveForm({
      ids: ids ? [ids] : [],
      data: data,
      resModel: resModel,
    }),
    onSuccess: async (data) => {
      await callButtonMutate({
        method: action?.name,
        resModel: resModel,
        ids: data?.[0]?.id ? data?.[0]?.id : [],
      });
    },
    onError: () => {
      showErrorMessage(`${action?.string} ${t('thất bại')}`);
    }
  });

  const handleButtonObject = async () => {
    if (action?.confirm) {
      setModalAction(prev => ({ ...prev, status: true }));
      return;
    }

    if (isButtonInside) {
      try {
        await webSaveMutate({
          method: action?.name,
          resModel: resModel,
          data: {
            ...removeUndefinedFields(context),
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await callButtonMutate({
          method: action?.name,
          resModel: resModel,
          ids: dataForm?.id ? [dataForm?.id] : [],
          context: {
            ...context,
            active_id: dataForm?.id,
            active_ids: [dataForm?.id],
            active_model: resModel
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleButton = async (actionId) => {
    if (action?.special === "cancel") {
      handleCloseActions()
    } else if (action?.type === 'object') {
      handleButtonObject();
    } else if (action?.type === 'action') {
      handleButtonAction(actionId);
    }
  };

  useEffect(() => {
    if (action?.invisible) {
      setHidden(matchDomains({ ...context, ...rootContext, ...contextAction }, action.invisible));
    }
  }, [context]);

  const disabled = loading || loadingWebSave || loadingCallButton;

  return (
    <>
      <button
        type="button"
        disabled={disabled}
        onClick={() => handleButton(action?.id)}
        className={`transition-opacity ${action?.class?.includes('oe_highlight') || action?.class?.includes('btn-primary') ? 'button-primary' : 'button-secondary'} ${isHidden && 'hidden'} ${disabled && 'cursor-not-allowed opacity-30 '}`}
      >
        {disabled ? `${action?.string}...` : action?.string}
      </button>

      {modalAction?.status && <ModalDynamic {...modalAction} handleCloseActions={handleCloseActions} handleOnchange={handleOnChange} formSpecification={formSpecification} />}

      <ModalLayer isOpen={isOpenSaveConfirm} onClose={() => setOpenSaveConfirm(false)}>
        <div className='mx-auto flex flex-col items-center justify-center gap-4'>
          <p className='text-[16px]'>{t("Bạn có muốn")} {action?.string}?</p>
          <div className='flex justify-center items-center gap-2'>
            <button
              type='button'
              onClick={() => {
                handleConfirm()
                setOpenSaveConfirm(false)
              }}
              className='button-primary'
            >
              Xác nhận
            </button>
            <button type='button' onClick={() => setOpenSaveConfirm(false)} className='button-secondary'>Đóng</button>
          </div>
        </div>
      </ModalLayer>
    </>
  );
};

export default ButtonView;