import React from 'react';

const Fee = ({ value, string, onchangeData, name, ...props }) => {
    return (
        <>
            {value?.subtotals?.[0]?.formatted_amount && value?.groups_by_subtotal?.[value?.subtotals?.[0]?.name]?.[0]?.formatted_tax_group_amount &&
                <div className='grid grid-cols-2 gap-x-4 gap-y-2 max-w-[300px] ml-auto'>
                    <div className='text-[14px] leading-[21px] text-right'>{value?.subtotals?.[0]?.name}:</div>
                    <div className='text-[14px] leading-[21px] font-bold text-[rgb(73,80,87)] text-right '>{value?.subtotals?.[0]?.formatted_amount}</div>

                    <div className='text-[14px] leading-[21px] text-right'>{value?.groups_by_subtotal?.[value?.subtotals?.[0]?.name]?.[0]?.tax_group_name}:</div>
                    <div className='text-[14px] leading-[21px] text-[rgb(73,80,87)] text-right '>{value?.groups_by_subtotal?.[value?.subtotals?.[0]?.name]?.[0]?.formatted_tax_group_amount}</div>
                </div>
            }
            <div className='grid grid-cols-2 gap-x-4 gap-y-2 max-w-[300px] ml-auto mt-2 pt-2 lg:mt-5 lg:pt-5 border-t'>
                <div className='text-[14px] leading-[21px] text-right'>{string}:</div>
                <div className='text-xl leading-[21px] font-bold text-[rgb(73,80,87)] text-right '>{onchangeData?.[name]?.formatted_amount_total}</div>
            </div>
        </>
    )
}

export default Fee

