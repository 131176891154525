import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Header from '../components/header'
import ThemeSettings from '../components/header/ThemeSettings'
import Sidebar from '../components/header/sidebar/SideBar'
import StoreService from '../core/services/company/StoreService'
import useScrollToTop from '../hooks/useScrollToTop'
import MetadataManager from '../metadata/MetadataManager'
import useHeaderStore from '../store/header'
import useThemeStore from '../store/theme'
import '../style/css/color.css'
import useBrowserSession from '../hooks/useBrowserSession'
import { SIDEBAR_TYPE } from '../constant/sidebar-type'
import { LocalStorageService } from '../util/lib/LocalStorage'
import { MetaProvider, useMetaContext } from '../util/context/MetaContext'
import { pagesRoute, publicRoutes } from './router.link'
import { hasAccessToken } from '../util/util'
import useSidebar from '../store/sidebar'
import { menu_focus } from '../util/redux/Action'
import { buildUrl } from './url'

const HeaderLayout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const data = useSelector((state) => state.toggle_header)
  const menuFocus = useSelector((state) => state.menu_focus)
  const { layoutStyling } = useThemeStore()
  const { openTouch, hover } = useMetaContext()
  const [toggleMenuMobile, setToggleMenuMobile] = useState(false)
  const { menuList, isNearLogin, setIsNearLogin } = useSidebar()

  const accessToken = hasAccessToken()
  useEffect(() => {
    if (accessToken && isNearLogin && menuList && Array.isArray(menuList)) {
      const defaultMenu = menuList[0]?.child_id?.[0]
      const id = defaultMenu?.action?.id?.id
      const model = defaultMenu?.action?.res_model
      const view = defaultMenu?.action?.binding_view_types?.split(',')[0]

      sessionStorage.setItem('parent_icon', JSON.stringify(menuList[0]))
      sessionStorage.setItem('url_icon', JSON.stringify(defaultMenu))
      dispatch(menu_focus(id))
      navigate(buildUrl(view, id, model))
      setIsNearLogin(false)
    }
  }, [accessToken, menuList, isNearLogin])

  return (
    <>
      <div className={`main-wrapper ${data ? 'header-collapse' : ''} flex`}>
        <div
          className={layoutStyling !== "horizontal" && `transitionSb relative h-full min-h-screen ${openTouch || hover ? 'lg:w-[260px] max-w-[260px] duration-700' : 'lg:w-[80px] duration-500'} transition-[width] ease-in-out block w-0`}
          style={{ flexShrink: 0, }}
        >
          <div
            className={layoutStyling !== "horizontal" && `sidebar-wrapper trasitionSb ${toggleMenuMobile} fixed left-0 top-0 z-40 h-[100vh] transition-all duration-700 ease-in-out ${openTouch || hover ? 'duration-900 w-[260px] transition-all ease-in-out' : 'w-[80px]'} ${toggleMenuMobile ? "translate-x-0" : "-translate-x-full lg:translate-x-0"}`}
          >
            <Sidebar setToggleMenuMobile={setToggleMenuMobile} />
          </div>
        </div>
        <div className='grow w-full'>
          <Header setToggleMenuMobile={setToggleMenuMobile} />
          <Outlet key={menuFocus} />
        </div>
      </div>
      <ThemeSettings />
    </>
  )
}
const AllRoutes = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const data = useSelector((state) => state.toggle_header)
  const location = useLocation()
  const { setRootContext } = useHeaderStore()
  const { setLayoutStyling } = useThemeStore()
  const navigate = useNavigate()
  const { i18n } = useTranslation()
  const { menuList, isNearLogin, setIsNearLogin } = useSidebar()

  const handleTabClose = useCallback(() => {
    try {
      LocalStorageService.clearToken()
      // sessionStorage.removeItem('token')
      // dispatch(actions.authLogout())
    } catch (error) {
      console.error('Error in handleTabClose:', error)
    }
  }, [dispatch])

  useBrowserSession(handleTabClose)

  useScrollToTop();

  useEffect(() => {
    const defaultSidebar = searchParams.get("menu")
    setLayoutStyling(defaultSidebar === SIDEBAR_TYPE.DEFAULT ? defaultSidebar : process.env.REACT_APP_SIDEBAR_TYPE)
    Cookies.set('i18next', 'vi')
    i18n.changeLanguage('vi')

    const accessToken = hasAccessToken()

    if (!accessToken && location.pathname !== '/forgot-password' && location.pathname !== '/reset-password') {
      navigate('/signin')
    } else {
      if (location.pathname === '/') {
        if (accessToken && menuList && Array.isArray(menuList)) {

          const defaultMenu = menuList[0]?.child_id?.[0]
          const id = defaultMenu?.action?.id?.id
          const model = defaultMenu?.action?.res_model
          const view = defaultMenu?.action?.binding_view_types?.split(',')[0]

          sessionStorage.setItem('parent_icon', JSON.stringify(menuList[0]))
          sessionStorage.setItem('url_icon', JSON.stringify(defaultMenu))
          dispatch(menu_focus(id))
          navigate(buildUrl(view, id, model))
        }
      }
    }
  }, [menuList])

  const Authpages = () => (
    <div className={`${data ? 'header-collapse' : ''}`}>
      <Outlet />
    </div>
  )
  const [id, setId] = useState(null)
  const [metadata, setMetadata] = useState(null)
  useEffect(() => {
    if (metadata && metadata.primary_color) {
      // const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary')
      document.documentElement.style.setProperty('--primary', metadata.primary_color);
      document.documentElement.style.setProperty('--secondary', metadata.secondary_color);
    } else {
      // document.documentElement.style.setProperty("--primary", "#000000"); // Example fallback color
    }
  }, [metadata])

  const [loadingImport, setLoadingImport] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [errorData, setErrorData] = useState()
  const [openTouch, setOpenTouch] = useState(true)
  const [hover, setHover] = useState(false)
  const accessToken = LocalStorageService.getAccessToken()
  const fetchCurrentCompany = async () => {
    await StoreService.getCurrentCompany().then((res) => {
      setId(res?.current_company_id)
      setRootContext({ allowed_company_ids: [res?.current_company_id] })
    })
  }
  const handleLoadingImport = (action) => {
    setLoadingImport(action)
  }
  const getUserCompany = async (companyId) => {
    await StoreService.getUserCompany(companyId).then((res) => {
      setMetadata(res?.records[0])
    })
  }

  useEffect(() => {
    accessToken && fetchCurrentCompany();
    if (id && (accessToken !== 'undefinded')) {
      getUserCompany(id);
    }
  }, [id, accessToken]);
  return (
    <div className='App'>
      <MetaProvider
        metadata={metadata}
        errorData={errorData}
        setErrorData={setErrorData}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        loadingImport={loadingImport}
        setLoadingImport={handleLoadingImport}
        openTouch={openTouch}
        setOpenTouch={setOpenTouch}
        setHover={setHover}
        hover={hover}
      >
        {metadata && <MetadataManager metadata={metadata} />}
        <Routes>
          <Route path={'/'} element={<HeaderLayout />}>
            {pagesRoute.map((route, id) => (
              <Route path={route.path} element={<route.element key={`${(route.name !== 'dashboard') && location.key}`} />} key={id} />
            ))}
          </Route>
          <Route path={'/'} element={<Authpages />}>
            {publicRoutes.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))}
          </Route>
        </Routes>
      </MetaProvider>
    </div>
  )
}
export default AllRoutes
