import { useCallback, useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import ExcelService from '../../core/services/import-excel'
import { useMetaContext } from '../../util/context/MetaContext'
import Loading from '../../components/general/Loading'
import useExcelStore from '../../store/excel'
import { useLocation, useNavigate } from 'react-router-dom'
import ImageWithBasePath from '../../components/general/ImageCustom'
import UploadButton from '../../components/tree/UploadButton'
import { useTranslation } from 'react-i18next'
import { SidebarContext } from '../../util/context/SidebarContext'
import ButtonLoading from '../../components/general/ButtonLoading'
import { showErrorMessage, showSuccessMessage } from '../../util/util'

const UploadFiles = ({ title }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resModal = queryParams.get('model');
  const vid = sessionStorage.getItem("vid") ?? ""
  const { dataParse, setDataParse } = useExcelStore()
  const [parsePreview, setParsePreview] = useState(dataParse)
  const { loadingImport, setLoadingImport, selectedFile, setErrorData, errorData, setSelectedFile } = useMetaContext()
  const [emptyFields, setEmptyFields] = useState([])
  const [skipRecord, setSkipRecord] = useState([])
  const [newValue, setNewValue] = useState({})
  const [dataMatch, setDataMatch] = useState([])
  const [option, setOption] = useState()
  const { idFile, setIdFile, setIsFileLoaded } = useExcelStore()
  const url_icon = JSON.parse(sessionStorage.getItem("url_icon")) ?? {}
  const parent_icon = JSON.parse(sessionStorage.getItem("parent_icon")) ?? {}
  const { t } = useTranslation()
  const navigate = useNavigate()

  const existIcon = url_icon?.url_icon ? url_icon : parent_icon

  const [selectedSheet, setSelectedSheet] = useState(dataParse?.options?.sheet || '');

  const [isHeader, setIsHeader] = useState(true)

  const handleSelectChange = async (selectedOption) => {
    setSelectedSheet(selectedOption ? selectedOption.value : '');
  };

  const handleDataParseChange = (newData) => {
    setDataParse(newData);
  };

  const FetchDataPreview = async (id) => {
    setLoadingImport(true)
    await ExcelService.ParsePreview({ id, selectedSheet: selectedSheet, isHeader: isHeader }).then((responsive) => {
      handleDataParseChange(responsive)
      setLoadingImport(false)

    })
  }

  console.log("loadingImport", loadingImport);

  useEffect(() => {
    if (idFile) {
      FetchDataPreview(idFile)
    }
  }, [selectedSheet, idFile, isHeader])

  const errorOptions = [
    {
      id: 1,
      title: 'Prevent Import',
    },
    {
      id: 2,
      title: 'Set value as empty',
      import_set_empty_fields: [],
    },
    {
      id: 3,
      title: 'Skip record',
      import_skip_records: [],
    },
    {
      id: 4,
      title: 'Create new values',
      name_create_enabled_fields: {},
    },
  ]
  function mergeAndFilterArrays(arrayOfObjects, objectWithArrays) {
    const result = []
    const objectEntries = Object.entries(objectWithArrays)

    objectEntries.forEach(([key, valueArray]) => {
      const matchingObject = arrayOfObjects.find((item) => valueArray.includes(item.name))
      if (matchingObject) {
        result.push({
          ...matchingObject,
          [key]: valueArray,
        })
      } else {
        result.push({
          name: valueArray[0], // Sử dụng phần tử đầu tiên của mảng làm tên
          [key]: valueArray,
        })
      }
    })

    return result
  }
  const updateResultItem = useCallback((index, data) => {
    setDataMatch((prevResult) => {
      const newResult = [...prevResult]
      newResult[index] = {
        ...newResult[index],
        string: data?.label,
        id: data?.value,
        type: data?.type,
      }
      setDataMatch(newResult)
      return newResult
    })
  }, [])
  const handleChangeField = useCallback(
    (id, data) => {
      updateResultItem(id,
        data)
    },
    [updateResultItem]
  )
  const handleChangeOption = (id, data) => {
    setErrorData((prevItems) =>
      prevItems.map((item) => (item.field === id ? { ...item, selectedOption: data.label, id: data.value } : item))
    )
    if (data.value == 2) {
      setEmptyFields((prevFields) => {
        if (!prevFields.includes(id)) {
          return [...prevFields, id]
        }
        return prevFields
      })
      setSkipRecord((prevFields) => prevFields.filter((fieldId) => fieldId !== id))
      setNewValue((prevFields) => {
        if (data.value != 4) {
          const { [id]: removedField, ...newFields } = prevFields
          return newFields
        }
      })
    } else if (data.value == 3) {
      setSkipRecord((prevFields) => {
        if (!prevFields.includes(id)) {
          return [...prevFields, id]
        }
        return prevFields
      })
      setEmptyFields((prevFields) => prevFields.filter((fieldId) => fieldId !== id))
      setNewValue((prevFields) => {
        if (data.value != 4) {
          const { [id]: removedField, ...newFields } = prevFields
          return newFields
        }
      })
    } else if (data.value == 4) {
      setEmptyFields((prevFields) => prevFields.filter((fieldId) => fieldId !== id))
      setSkipRecord((prevFields) => prevFields.filter((fieldId) => fieldId !== id))
      setNewValue((prevFields) => {
        if (data.value == 4) {
          return { ...prevFields, [id]: true }
        } else {
          const { [id]: removedField, ...newFields } = prevFields
          return newFields
        }
      })
    } else {
      setEmptyFields((prevFields) => prevFields.filter((fieldId) => fieldId !== id))
      setSkipRecord((prevFields) => prevFields.filter((fieldId) => fieldId !== id))
      setNewValue((prevFields) => {
        if (data.value != 4) {
          const { [id]: removedField, ...newFields } = prevFields
          return newFields
        }
      })
    }
  }

  function extractStringValues(array, requiredLength) {
    const processedItems = array.map((item) => (item.string != '' && item.id ? item.id.toLowerCase() : false))
    const remainingItems = Array(Math.max(0, requiredLength - processedItems.length)).fill(false)
    // Kết hợp các phần tử đã xử lý và các phần tử bổ sung
    return [...processedItems, ...remainingItems]
  }
  const { setIsLoading, isLoading, setIsLoadingTest, isLoadingTest } = useContext(SidebarContext)

  const handleImport = async (dryrun, setLoading) => {
    setErrorData()
    setLoading(true)
    if (dataMatch && dataParse?.headers) {
      const columns = dataParse?.headers
      const options = { ...dataParse?.options, sheet: selectedSheet, has_headers: isHeader }
      const fields = extractStringValues(dataMatch, columns?.length)

      await ExcelService.ExecuteImport({
        fields,
        columns,
        idFile,
        options,
        dryrun,
      })
        .then((responsive) => {
          setLoading(false)
          if (!responsive?.ids) {
            const newData = responsive?.messages?.map((item) => ({
              ...item,
              selectedOption: '',
              id: '',
            }))
            showErrorMessage(dryrun ? t('excel-9') : t('excel-6'))
            setErrorData(newData)
          } else {
            showSuccessMessage(dryrun ? t('excel-8') : t('excel-5'))
            if (!dryrun) {
              navigate(`/list?vid=${vid}&model=${resModal}`)
              setIdFile(null)
              setSelectedFile(null)
              setIsFileLoaded(false)
            }
            location.reload()
          }
        })
    }
  }

  useEffect(() => {
    if (dataParse.matches && dataParse.fields) {
      const result = mergeAndFilterArrays(dataParse.fields, dataParse.matches)
      setDataMatch(result)
    }
    if (dataParse?.options) {
      setOption(dataParse?.options)
    }
    if (dataParse) {
      setParsePreview(dataParse)
      setLoadingImport(false)
    }
  }, [dataParse.matches, dataParse.fields, dataParse?.options])

  return (
    <div className='page-wrapper'>
      <div className='my-[32px] flex justify-between items-center'>
        <div className='flex gap-4 '>
          <div className="icon p-2 border border-[#E2E4E9] rounded-full flex justify-center items-center bg-primary w-12 h-12">
            <img
              src={existIcon?.url_icon && existIcon?.url_icon?.startsWith("http") ? existIcon.url_icon : process.env.REACT_APP_DOMAIN + existIcon.url_icon}
              width={20}
              height={20}
              alt="img"
            />
          </div>
          <div>
            <div className='title text-xl tracking-wide text-dark font-semibold'>{title?.toUpperCase()}</div>
            <div className='text-[20px] font-normal text-[#16A730]'>{t('upload-excel')}</div>
          </div>
        </div>
        <div className='flex gap-[16px]'>
          <ButtonLoading type='button' loading={isLoading} func={() => handleImport(false, setIsLoading)} className='rounded-[10px] border-[1px] border-solid border-[#16A730] bg-[#fff] p-[10px] font-medium text-[#0B701D]'
            content={t('import')} />
          <ButtonLoading type='button' loading={isLoadingTest} func={() => handleImport(true, setIsLoadingTest)} className='rounded-[10px] border-[1px] border-solid border-[#E39717] bg-[#fff] p-[10px] font-medium text-[#E39717]'
            content={t('test')} />
          <UploadButton />
        </div>
      </div>
      <div className='flex gap-[24px] flex-col'>
        <div className='grid grid-cols-3 gap-[32px] rounded-[10px] bg-[#FFF] border-[1px] border-solid border-[#F2F2F2] p-[24px]' style={{ boxShadow: "0px 1px 8px 0px rgba(82,88,102,0.06)" }}>
          <div className='flex flex-col gap-[2px]'>
            <div className='text-[14px] text-[#0E121B] font-medium'>{t('imported-file')}</div>
            <div className='bg-[#FFF] flex gap-3 rounded-xl border-[1px] border-solid border-[#E2E4E9] p-4'>
              <ImageWithBasePath width={40} height={40} src={'assets/img/xlsx.svg'}
                alt={'img'}
              />
              <div className='flex flex-col gap-1'>
                <div className='text-[14px] font-medium text-[#121212]'>{selectedFile?.name}</div>
                <div className='flex gap-1 items-center'>
                  <div className='text-[12px] font-normal text-[#525252]'>{selectedFile?.size} KB</div>
                  <div className='w-[4px] h-[4px] rounded-full bg-[rgba(82,88,102,1)]'>
                  </div>
                  <ImageWithBasePath width={16} height={16} src={'assets/img/completed.svg'}
                    alt={'img'}
                  />
                  <div className='text-[12px] font-normal text-[#121212]'>{t('completed')}</div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-2'>
            <div className='text-[14px] font-medium text-[#0E121B]'>{t('sheet')}</div>
            <Select
              classNamePrefix="react-select !w-full"
              options={dataParse?.options?.sheets?.map((sheet) => ({
                label: sheet,
                value: sheet,
              }))}
              value={selectedSheet ? { value: selectedSheet, label: selectedSheet } : null}
              onChange={handleSelectChange} // Step 4: Attach the onChange handler
              placeholder={t("choose_place")}
              isClearable

            />
            <div className='flex gap-[6px]'>
              <div className=''>
                <input type='checkbox' checked={isHeader} onChange={() => setIsHeader(!isHeader)}  // Toggle the state when clicked
                  width={16} height={16} />
              </div>
              <div className='text-[12px] font-normal text-[#525866]'>{t('excel-des')}</div>
            </div>
          </div>
          <div className='flex flex-col gap-[8px]'>
            <div className='text-[14px] font-medium text-[#0E121B]'>{t('help')}</div>
            <div className='text-[14px] font-normal text-[#525866]'> {t('col-3-des-title')}</div>
            <div className='text-[14px] font-normal text-[#525866]'>{t('col-3-des-subtile')} </div>
          </div>
        </div>
        <div className='flex w-full flex-col'>
          {errorData?.length > 0 && (
            <div className='flex w-full flex-col gap-[8px] mb-[24px] rounded-[4px] bg-[#FFDFE0] p-[8px] font-bold text-[#0A0D14]'>
              The file contains blocking errors (see below)
              <div>{errorData[0]?.message}</div>
            </div>
          )}
          <div className='w-full grid grid-cols-5 gap-[24px] pl-[24px] bg-[#FFF] rounded-tr-[10px] rounded-tl-[10px]' style={{ boxShadow: "0px 1px 8px 0px rgba(82,88,102,0.06)" }}>
            <div className='font-medium text-[14px] text-[#121212] py-[14px]'>{t('file-field')}</div>
            <div className='font-medium text-[14px] text-[#121212] py-[14px]'>{t('vitrust-field')}</div>
            <div className='font-medium text-[14px] text-[#121212] py-[14px]'>{t('type-field')}</div>
            <div className='font-medium text-[14px] text-[#121212] py-[14px]'>{t('value-field')}</div>
            <div className='font-medium text-[14px] text-[#121212] py-[14px]'>{t('comment-field')}</div>
          </div>
          {loadingImport ? (
            <Loading />
          ) : (
            <div className='flex flex-col gap-[24px] py-[24px] px-[24px] bg-[#FFF] rounded-br-[10px] rounded-bl-[10px]' style={{ boxShadow: "0px 1px 8px 0px rgba(82,88,102,0.06)" }}>
              {parsePreview?.headers !== false && parsePreview?.headers?.map((item, index) => {
                const { string, type } = dataMatch.find(item => item?.[index]) ?? {}

                return (
                  <div className='flex w-full flex-col gap-[16px]'>
                    <div className='grid grid-cols-5 gap-[32px]'>
                      <div
                        key={index}
                        className='text-[14px] font-medium text-[#0E121B]'
                      >
                        {item == '' ? 'Unitled' : item}
                      </div>
                      <div className={`relative flex items-start gap-[8px] ${index} ${dataParse?.matches?.[index]?.[0]} ${dataMatch[index]?.string}`}>
                        <Select
                          classNamePrefix='react-select !w-full'
                          options={dataParse?.fields?.map((item) => {
                            return {
                              label: item.string,
                              value: item.id,
                              type: item.type,
                            }
                          })}
                          value={{
                            value: dataParse?.matches?.[index]?.[0],
                            label: string,
                            type: type,
                          }}
                          placeholder={'To import, select a field...'}
                          onChange={(selectedOption) => handleChangeField(index, selectedOption)}
                          isClearable
                          menuPlacement={parsePreview?.headers?.length - 1 === index || parsePreview?.headers.length - 2 === index || parsePreview?.headers.length - 3 === index ? 'top' : 'bottom'}
                        />
                      </div>
                      <div className='text-[14px] text-[#525866] font-normal'>{dataMatch[index]?.type}</div>
                      <div className='text-[14px] text-[#525252] font-normal'>{parsePreview?.preview[index]?.[0]}</div>
                      <div>
                        {errorData &&
                          errorData
                            .filter((field) => field?.field == dataMatch[index]?.id)
                            .slice(0, 1)
                            .map((subField, subIndex) => {
                              return (
                                <div key={subIndex} className='flex flex-col gap-[8px]'>
                                  <div className='w-full flex gap-2 items-start rounded-[4px] bg-[#FFDFE0] text-[12px] p-[8px] font-normal text-[#0A0D14]'>
                                    <ImageWithBasePath width={16} height={16} src={'assets/img/error.svg'}
                                      alt={'img'}
                                    />
                                    {t('error-1')} {subField?.field_name}
                                  </div>
                                  {dataParse?.matches?.[index]?.[0] && (
                                    <div className='w-full rounded-[4px] bg-[#FFDFE0] p-[8px] font-medium text-[12px] text-[#0A0D14]'>
                                      {t('error-2')}
                                      <Select
                                        classNamePrefix='react-select !w-full'
                                        options={errorOptions.map((item) => {
                                          return {
                                            label: item.title,
                                            value: item.id,
                                          }
                                        })}
                                        value={{
                                          value: subField?.id != '' ? subField.id : errorOptions[0].id,
                                          label:
                                            subField?.selectedOption != ''
                                              ? subField.selectedOption
                                              : errorOptions[0].title,
                                        }}
                                        placeholder={'To import, select a field...'}
                                        onChange={(selectedOption) =>
                                          handleChangeOption(dataParse?.matches?.[index]?.[0], selectedOption)
                                        }
                                        isClearable
                                      />
                                    </div>
                                  )}
                                </div>
                              )
                            })}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default UploadFiles


