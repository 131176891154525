export const all_routes = {
  dashboard: '/admin-dashboard',
  salesdashboard: '/sales-dashboard',
  productdetails: '/product-details/:id',
  variantdetails: '/variant-details/:id',
  variantattributes: '/variant-attributes',
  purchaseorderreport: '/purchase-order-report',
  banksettingsgrid: '/bank-settings-grid',
  taxrates: '/tax-rates',
  currencysettings: '/currency-settings',
  banipaddress: '/ban-ip-address',
  generalsettings: '/general-settings',
  securitysettings: '/security-settings',
  notification: '/notification',
  connectedapps: '/connected-apps',
  systemsettings: '/system-settings',
  companysettings: '/company-settings',
  localizationsettings: '/localization-settings',
  prefixes: '/prefixes',
  preference: '/preference',
  saleslist: '/sales-list',
  salescreate: '/sales-order',
  fromview: '/form-view',
  newstudents: 'new-students',
  resetpassword: '/reset-password',
  forgotpassword: '/forgot-password',

  /* ----- Invoice ----- */
  invoicereport: '/invoice-report',
  invoicedetail: '/invoice-detail/:id',
  invoiceedit: '/invoice-edit/:id',
  invoiceadd: '/invoice-add',

  /* ----- Search ----- */
  profile: '/profile',
  signin: '/signin',
  signintwo: '/signin-2',
  signinthree: '/signin-3',
  error404: '/error-404',
  error500: '/error-500',
  users: '/users',
  fees: '/fees',
  rolespermission: '/roles-permissions',
  editpermissions: '/permissions-edit/:id',
  deleteaccount: '/delete-account',

  elearning: "/elearning"
}
