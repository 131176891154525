import { callPath, createPath, loadActionPath, reportPath, runActionPath } from '../../../constant/endpoint'
import { getSubdomain, toQueryString } from '../../../util/util'
import requests from '../AxiosClient'

const ActionService = {
    callButton: async ({ resModel, ids = [], context, method }) => {
        try {
            const jsonData = {
                model: resModel,
                method: method,
                ids: ids,
                with_context: context,
            }
            return requests.post(callPath, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        } catch (error) {
            console.error('Error when calling button action:', error)
            throw error

        }
    },
    callBoxButton: async ({ resModel, ids = [], context, method }) => {
        try {
            const jsonData = {
                ids: ids,
                kwargs: context,
                model: resModel,
                method: method,
            }
            return requests.post(callPath, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        } catch (error) {
            console.error('Error when calling button action:', error)
            throw error

        }
    },
    getAcTionDetail: async ({ resModel, ids = [], context, method }) => {
        const jsonData = {
            model: resModel,
            method: method,
            ids: ids,
            with_context: context,
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    removeRows: async ({ model, ids }) => {
        const jsonData = {
            model: model,
            method: 'unlink',
            ids: ids
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    duplicateModel: async ({ model, ids }) => {
        const jsonData = {
            model: model,
            method: 'copy',
            ids: ids
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    copyAddressModel: async ({ ids }) => {
        const jsonData = {
            model: "payment.link.wizard",
            method: "onchange",
            ids: [],
            args: [
                {},
                [],
                {
                    amount: {},
                    amount_max: {},
                    amount_paid: {},
                    confirmation_message: {},
                    currency_id: {
                        fields: {}
                    },
                    link: {},
                    partner_email: {},
                    partner_id: {
                        fields: {}
                    },
                    res_id: {},
                    res_model: {},
                    warning_message: {}
                }
            ],
            with_context: {
                active_id: ids, // id của invoices
                active_ids: [
                    ids // id của invoices
                ],
                active_model: "account.move",
                allowed_company_ids: [
                    1
                ],
                default_move_type: "out_invoice",
                lang: "vi_VN",
                tz: "Asia/Saigon",
                uid: 2
            }
        };
        return requests.post(`${callPath}?db=${getSubdomain()}`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    getListUserModel: async ({ limit = 10 }) => {
        const jsonData = {
            model: "res.partner",
            method: "web_search_read",
            ids: [],
            kwargs: {
                specification: {
                    id: {},
                    name: {}
                },
                offset: 0,
                order: "",
                limit: limit,
                count_limit: 10001,
                domain: []
            }
        };
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    createRecord: async ({ id, ids, note }) => {
        const jsonData = {
            model: "portal.share",
            values: {
                res_model: "account.move",
                res_id: id,  // id của invoices
                partner_ids: ids,
                note: note
            }
        };
        return requests.post(createPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    sendMail: async ({ ids }) => {
        const jsonData = {
            model: "portal.share",
            method: "action_send_mail",
            ids: ids
        };
        return requests.post(`${callPath}?db=${getSubdomain()}`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    getPrintReportName: async ({ ids, }) => {
        const jsonData = {
            model: "ir.actions.report",
            method: "web_read",
            ids: ids,
            kwargs: {
                specification: {
                    report_name: {}
                }
            }
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    getDataSmsTextMessage: async ({ id }) => {
        const jsonData = {
            model: "sms.composer",
            method: "onchange",
            ids: [],
            args: [
                {},
                [],
                {
                    recipient_single_number: {},
                    recipient_single_description: {},
                    recipient_single_number_itf: {},
                    res_id: {},
                    comment_single_recipient: {},
                    res_model_description: {},
                    res_model: {}
                }
            ],
            with_context: {
                active_id: id,
                active_ids: [id],
                active_model: "res.partner",
                allowed_company_ids: [1]
            }
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    createRecordSmsTextMessage: async ({ id, phone, message }) => {
        const jsonData = {
            model: "sms.composer",
            values: {
                body: message,  // Message
                composition_mode: "comment",
                recipient_single_number_itf: phone,
                res_id: id,
                res_model: "res.partner"
            }
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    sendSmsTextMessage: async ({ id }) => {
        const jsonData = {
            model: "sms.composer",
            method: "action_send_sms",
            ids: [
                id
            ]
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    loadAction: async ({ idAction, idDetail, model, }) => {
        const jsonData = {
            action_id: idAction,
            additional_context: {
                active_id: idDetail,
                active_ids: [idDetail],
                active_model: model
            }
        }
        return requests.post(`${loadActionPath}?db=${getSubdomain()}`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    runAction: async ({ idAction, idDetail, model, }) => {
        const jsonData = {
            action_id: idAction,
            context: {
                lang: "en_US",
                tz: "Asia/Saigon",
                uid: 2,
                allowed_company_ids: [
                    1
                ],
                active_id: idDetail, //id cua detail
                active_ids: [
                    idDetail  //id cua detail
                ],
                active_model: model
            }
        }
        return requests.post(`${runActionPath}?db=${getSubdomain()}`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    print: async ({ ids, report, db }) => {
        const jsonData = {
            report: report,
            ids: ids,
            type: 'pdf',
            file_response: true,
            db: db
        };

        const queryString = toQueryString(jsonData);
        const urlWithParams = `${reportPath}?${queryString}`;

        console.log("🚀 ~ print: ~ urlWithParams:", urlWithParams)
        return requests.get(urlWithParams, {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'arraybuffer',
        });
    },
}

export default ActionService
