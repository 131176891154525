import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableView from '../../table/view/table-view';
import useTable from '../../table/model/table';
import ModelService from '../../../core/services/model-service/model-service';
import { evalJSONContext, evalJSONDomain, filterFieldDirty, getSpecificationByFields } from '../../../util/util';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Many2ManyView from './many2many-view';
import { useSearchParams } from 'react-router-dom';
import useTableStore from '../../../store/table';
import { useQuery } from '@tanstack/react-query';
import useHeaderStore from '../../../store/header';
import { FIELD_TYPE } from '../../../constant/field-type';
import { COMPONENT_TYPE, OBJECT_POSITION } from '../../../constant/componen-type';
import { Domain, matchDomains } from '../../../core/domain/domain';
import ModalTreeViewForm from '../../modal/ModalTreeViewForm';

const TreeViewForm = ({ viewData, resModel, tab, context, handleOnchange, title, onchangeData, setOnchangeData }) => {
    const { watch, control } = useFormContext()
    const relation = viewData?.models?.[resModel]?.[tab?.name]?.relation ?? resModel
    const [isModalTreeView, setIsModalTreeView] = useState(false)
    const [isModalFormView, setIsModalFormView] = useState(false)
    const [searchParams] = useSearchParams()
    const vid = parseInt(searchParams.get("vid"))
    const id = parseInt(searchParams.get("id"))
    const { fields, setSelectedRowKeys, setIndexRowTableModal } = useTableStore()
    const relationField = viewData?.models?.[resModel]?.[tab?.name]?.relation_field
    const [domainTreeViewForm, setDomainTreeViewForm] = useState((tab?.domain && onchangeData) ? evalJSONDomain(tab?.domain, ModelService.parseORMOdoo(onchangeData)) : [])
    const contextTab = (tab?.context && onchangeData) ? { ...evalJSONContext(tab?.context, { ...onchangeData }), ...evalJSONContext(context, { ...onchangeData }) } : {}
    const objVal = relationField ? { [relationField]: onchangeData } : {}

    const { columns, visibleOptionalFields } = useTable({
        context: onchangeData,
        fields: fields?.[`${vid}_${relation}_tab${tab?.index}`] || tab.fields,
        data: onchangeData?.[tab?.name],
        dataModel: viewData?.models?.[relation],
        model: relation,
        tabIndex: tab?.index
    })

    const specification = getSpecificationByFields(tab?.fields, {}, viewData, relation)

    const { append, remove, } = useFieldArray({
        control,
        name: tab?.name,
    });
    const fieldArrayValues = watch(tab.name) || [];

    const methods = useFormContext()
    const { dirtyFields, isDirty } = methods.formState
    const { rootContext } = useHeaderStore()

    const handleChoose = async (id) => {
        try {
            const onchangeReponse = await ModelService.getDetailData({
                ids: id ?? [],
                model: relation,
                specification: specification,
                context: { ...rootContext, ...contextTab },
            })

            if (onchangeReponse?.length > 0) {
                onchangeReponse.forEach((dataItem) => {
                    append(ModelService.toDataJS(dataItem, viewData, relation))
                })

                handleClose()
                setSelectedRowKeys([])
            }

            return onchangeReponse
        } catch (err) {
            console.log(err);
        }
    }

    const handleAddLine = async (id) => {
        const { modelTab } = tab
        if ((modelTab?.domain?.length > 0 && modelTab?.type === "many2many") || tab?.widget === "many2many" || tab?.type === "many2many") {
            const ids = fieldArrayValues?.length > 0 && fieldArrayValues.map(value => value.id)
            const domains = new Domain([...evalJSONDomain(tab?.domain, ModelService.parseORMOdoo(onchangeData)), "!", ["id", "in", ids]])
            setDomainTreeViewForm(domains.toList())
            setIsModalTreeView(true)
        } else if (tab?.editable === "bottom") {
            try {
                if (!id) {
                    const onchangeReponse = await ModelService.onChangeForm({
                        ids: id ? [id] : [],
                        model: relation,
                        specification: specification,
                        context: { ...rootContext, ...contextTab },
                        object: objVal
                    })

                    if (onchangeReponse?.value) {
                        append(ModelService.toDataJS(onchangeReponse?.value, viewData, relation))
                        setIndexRowTableModal(fieldArrayValues.length)
                    }
                }

            } catch (err) {
                console.log(err);
            }
        } else {
            setIsModalFormView(true)
        }
    }

    const handleClose = () => {
        setIsModalTreeView(false)
    }

    // fetch field can onchange data
    const { data: fieldsOnchange } = useQuery({
        queryKey: [`field-onchange-${relation}`, relation],
        queryFn: () => ModelService.getFieldWithOnchange({ model: relation }),
        refetchOnWindowFocus: false,
    })

    // get default form data
    const fetchOnchange = async ({ id, model, specification, context, objVal, fieldChange }) => {
        try {
            const onchangeReponse = await ModelService.onChangeForm({
                ids: id ? [id] : [],
                model: model,
                specification: specification,
                context: context,
                object: objVal,
                fieldChange: fieldChange,
            })
            return onchangeReponse?.value
        } catch (err) {
            console.log(err)
        }
    }

    //handle onchange value
    const handleOnchangeTreeForm = (nameField, value) => {
        const nameItems = nameField.split('.')
        const rootField = nameItems[0]
        const index = parseInt(nameItems[1])
        const name = nameItems[2]
        const data = ModelService.parseORMOdoo(fieldArrayValues[index])

        if (fieldsOnchange?.includes(name)) {
            const fetchData = async () => {
                //handle dirty fields
                const dirtyFieldTreeForm = dirtyFields?.[rootField][index]
                // const obj = { ...objVal, ...data, [name]: value }

                const obj = {
                    ...objVal,
                    ...ModelService.parseORMOdoo(filterFieldDirty(id, viewData, data, dirtyFieldTreeForm, relation, onchangeData)),
                    [name]: value
                }

                // const fieldChange = Object.entries(dirtyFieldTreeForm)?.map(([key, value]) => {
                //   if (value === true) return key
                // })
                // for (const key of Object.keys(obj)) {
                //   obj[key] = data[key]
                // }
                // obj[name] = value

                // fetch onchange
                const dataOnchange = await fetchOnchange({
                    id: data?.id,
                    model: relation,
                    specification: specification,
                    context: { ...rootContext, ...contextTab },
                    objVal: ModelService.toDataJS(obj),
                    fieldChange: [name]
                })

                const updatedData = { ...data, [name]: value, ...ModelService.toDataJS(dataOnchange) };
                const dataOnchangeJS = ModelService.toDataJS(updatedData, viewData, relation)

                if (dataOnchangeJS) {
                    Object.keys(dataOnchangeJS).forEach((key) => {
                        if ((viewData?.models?.[relation]?.[key]?.type === FIELD_TYPE.ONE2MANY) || (viewData?.models?.[relation]?.[key]?.type === FIELD_TYPE.MANY2MANY)) {
                            methods.setValue(`${rootField}.${index}.${key}`, (dataOnchangeJS[key] ??= []).map((item) => {
                                const subRelation = viewData?.models?.[relation]?.[key]?.relation
                                if (viewData?.models?.[subRelation]) {
                                    if (item?.length >= 3) {
                                        return ModelService.toDataJS(item[OBJECT_POSITION], viewData, subRelation)
                                    } else {
                                        return ModelService.toDataJS(item, viewData, subRelation)
                                    }
                                } else {
                                    if (item?.length >= 3) {
                                        return item[OBJECT_POSITION]
                                    } else {
                                        return item
                                    }
                                }
                            }), { shouldDirty: true })
                        } else {
                            methods.setValue(`${rootField}.${index}.${key}`, dataOnchangeJS[key])
                        }
                    })
                }
            }
            fetchData()
        }
    }

    const handleRemoveLine = (indexRow) => {
        remove(indexRow)
        setOnchangeData(pre => ({ ...pre, [tab?.name]: [...onchangeData[tab?.name]].filter((_, index) => index !== indexRow) }))
    }

    return (
        <>
            <TableView
                viewData={viewData}
                resModel={resModel}
                handleOnchange={handleOnchangeTreeForm}
                rootField={tab}
                funcAddLine={handleAddLine}
                funcRemoveLine={remove}
                model={relation}
                isForm={false}
                dataSource={fieldArrayValues}
                columns={columns}
                dataResponseLength={fieldArrayValues.length}
                tabIndex={tab?.index}
                visibleOptionalFields={visibleOptionalFields}
                showFooterTable={tab?.create ? matchDomains(onchangeData, tab?.create) : !matchDomains(onchangeData, tab?.readonly)}
                showCheckBox={false}
                typeCo={COMPONENT_TYPE.FORM}
            />
            {isModalTreeView &&
                <Many2ManyView
                    title={title}
                    tab={tab}
                    relation={relation}
                    domain={domainTreeViewForm}
                    setDomain={setDomainTreeViewForm}
                    context={{ ...rootContext, ...contextTab }}
                    append={append}
                    funcChoose={handleChoose}
                    funcClose={handleClose}
                    typeCo={COMPONENT_TYPE.TREE}
                />
            }

            {isModalFormView &&
                <ModalTreeViewForm
                    context={{ ...rootContext, ...contextTab }}
                    resModel={relation}
                    handleCloseModal={() => setIsModalFormView(false)}
                    onchangeData={onchangeData}
                    title={"Thêm"}
                    index={fieldArrayValues.length}
                    tab={tab}
                />
            }
        </>
    )
}

TreeViewForm.propTypes = {
    viewData: PropTypes.object,
    onchangeData: PropTypes.object,
    resModel: PropTypes.string
}

export default TreeViewForm