import React, { useMemo } from 'react'
import StatusBar from '../../widget/status-bar/Statusbar'
import { useTranslation } from 'react-i18next'
import ButtonView from './button-view'
import CopyClipboardButton from '../../widget/CopyClipboardButton'

const ActionsView = ({ data, viewData, resModel, refetch, actions, isForm, specification, formSubmitRef, isMainForm, handleOnchange, formSpecification, isButtonInside, handleCloseActions }) => {
    const { t } = useTranslation()
    const stateData = useMemo(() => {
        return viewData?.models?.[resModel]?.state?.selection || []
    }, [resModel])

    const submitForm = () => {
        if (isMainForm) return
        if (formSubmitRef?.current) {
            formSubmitRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        }
    }

    return (data?.state && actions.length > 0) && (
        <div className={`w-full items-center bg-white rounded-[10px] !flex flex-col-reverse justify-center lg:!justify-between lg:!flex-row gap-y-[15px] ${isForm && "border border-[#F2F2F2] p-6"}`}>
            {isForm && <div className='col-span-1'>
                <StatusBar statusOptions={stateData} name={''} defaultValue={data?.state} />
            </div>}

            <div className='lg:justify-end md:justify-start flex gap-2 flex-wrap'>
                {actions?.map((action, index) => {
                    if (action?.widget === "CopyClipboardButton" && action?.link) return <CopyClipboardButton value={action?.link}>Sao chép</CopyClipboardButton>
                    if (data && action?.type_co === 'button')
                        return (
                            <ButtonView
                                key={index}
                                resModel={resModel}
                                dataForm={data}
                                action={action}
                                context={data}
                                viewData={viewData}
                                refetch={refetch}
                                specification={specification}
                                submitForm={submitForm}
                                handleOnchange={handleOnchange}
                                formSpecification={formSpecification}
                                isButtonInside={isButtonInside}
                                handleCloseActions={handleCloseActions}
                            />
                        )
                })}
            </div>
        </div>)
}

export default ActionsView
