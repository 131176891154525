import ImageWithBasePath from '../../general/ImageCustom';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ActionService from '../../../core/services/actions';
import { useTranslation } from 'react-i18next';
import LayerLoading from '../../general/LayerLoading';
import { downloadPDF, getSubdomain, showErrorMessage, showSuccessMessage } from '../../../util/util';
import ModalDynamic from '../../modal/ModalDynamic';
import { useClickOutside } from '../../../hooks/useClickOutside';
import useActionHandler from '../../../hooks/useActionButton';

const ButtonIcon = ({ onClick, iconSrc, children, className }) => {
  return (
    <button type='button' onClick={onClick} className={`size-8 p-2 border rounded-lg bg-white border-[#DEE7DD] cursor-pointer select-none ${className}`}>
      {iconSrc && <ImageWithBasePath src={iconSrc} />}
      {children}
    </button>
  );
};

const ButtonAction = ({ onClick, iconSrc, children, className, isLoading }) => {
  return (
    <button
      type='button'
      onClick={onClick}
      disabled={isLoading}
      className={`flex w-full rounded-lg px-4 py-2 hover:bg-[#eeecec] font-normal text-sm text-[#121212] tracking-[2%] text-start ${isLoading ? 'opacity-60 cursor-wait' : ''} ${className}`}
    >
      {iconSrc && <ImageWithBasePath src={iconSrc} />}
      {children}
    </button>
  );
};

const LeftToolbar = (props) => {
  const { isShow, actionsPrint, title, subTitle, actions, funcReset, model, handleOnchange, refetch, formValues, formSpecification } = props;
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams();
  const hostname = window.location.hostname;
  const detailId = parseInt(searchParams.get('id'))
  const [isOnActions, setOnActions] = useState(false);
  const [loading, setLoading] = useState(false);
  const actionsRef = useClickOutside(() => setOnActions(false))

  const url_icon = JSON.parse(sessionStorage.getItem("url_icon")) ?? {};
  const parent_icon = JSON.parse(sessionStorage.getItem("parent_icon")) ?? {};
  const existIcon = url_icon?.url_icon ? url_icon : parent_icon;

  const { modalAction, handleButtonAction, handleCloseActions } = useActionHandler({
    context: formValues,
    resModel: model,
    dataForm: { id: detailId },
    refetch,
  });

  const updateIdInSearchParams = (newId) => {
    searchParams.set('id', newId);
    setSearchParams(searchParams);
  };

  const renderSubmenu = () => {
    const handleClick = async ({ id, name = 'DefaultName' }) => {
      if (!id) return showErrorMessage('No such report')
      setLoading(true)
      try {
        const res = await ActionService.getPrintReportName({ ids: id })
        const db = getSubdomain(hostname)
        const res1 = await ActionService.print({ ids: detailId, report: res[0]?.report_name, db: db })
        downloadPDF(res1, name)
      } catch (err) {
        console.log('error: ', err)
        showErrorMessage(err?.message || 'Lỗi hệ thống')
      } finally {
        setLoading(false)
      }
    }

    return (
      <div className={`left-[calc(100%+10px)] hidden group-hover:block top-0 absolute shadow-[0_12px_16px_-1px_rgba(0,0,0,0.04),0_1px_5px_0_rgba(0,0,0,0.04)] min-w-fit w-[320px] bg-white rounded-xl p-2 border border-[#E5E5E5] z-[100]`}>
        <div className='absolute top-0 -left-5 w-10 h-full bg-transparent'></div>
        {actionsPrint && actionsPrint.length > 0 ? actionsPrint.map((action, index) => {
          return (
            <ButtonAction key={'button-action-submenu-' + index} onClick={() => handleClick({ id: action?.id, name: action?.name })}>
              {action?.name}
            </ButtonAction>
          )
        }) : <div className='text-center text-sm text-[#121212]'>Không có dữ liệu</div>}
      </div>
    )
  }

  const renderMoreAction = () => {
    return (
      <div >
        {actions && actions.length > 0 && <div className='border-t pt-2'>
          {actions.map((action, index) => {
            return (
              <ButtonAction key={'button-action-more-' + index} onClick={() => handleButtonAction(action?.id)} >
                {action.name}
              </ButtonAction>
            )
          })}
        </div>
        }
        {modalAction?.status && <ModalDynamic {...modalAction} handleCloseActions={handleCloseActions} handleOnchange={handleOnchange} formSpecification={formSpecification} />}
      </div>
    )
  }

  const renderActions = () => {
    const handleDuplicate = async () => {
      setLoading(true)
      try {
        const res = await ActionService.duplicateModel({ model: model, ids: [detailId] })
        if (res) {
          showSuccessMessage(t('successs_duplicate'))
          updateIdInSearchParams(res[0]?.[0])
        }
      } catch (err) {
        console.log('error: ', err)
        showErrorMessage(err?.message || 'Lỗi hệ thống')
      } finally {
        setLoading(false)
      }
    }

    return (
      <div
        className='group-actions flex gap-2 h-full items-end'>
        <div ref={actionsRef} onClick={() => setOnActions(!isOnActions)} className='size-8 border rounded-lg bg-white border-[#DEE7DD] cursor-pointer select-none relative flex justify-center items-center'>
          <ImageWithBasePath src="assets/img/setting.svg" width={16} height={16} className="w-4 h-4" />

          <div onClick={e => e.stopPropagation()} className={`${!isOnActions ? "hidden" : 'z-[3] absolute top-[calc(100%+10px)] start-0'} shadow-[0_12px_16px_-1px_rgba(0,0,0,0.04),0_1px_5px_0_rgba(0,0,0,0.04)] min-w-fit w-[320px] bg-white rounded-xl p-2 border border-[#E5E5E5] `}>
            {actionsPrint && actionsPrint?.length > 0 && <ButtonAction className={'group'}>
              <div className='flex w-full gap-3'>
                <ImageWithBasePath src='assets/img/icons/printer-2.svg' />
                <span className='font-normal text-sm text-[#121212] tracking-[2%]'>In</span>
              </div>
              <ImageWithBasePath src='assets/img/icons/arrow-right.svg' />
              {renderSubmenu()}
            </ButtonAction>}

            <ButtonAction onClick={handleDuplicate}>
              <div className='flex w-full gap-3'>
                <ImageWithBasePath src='assets/img/icons/copy.svg' />
                <span className='font-normal text-sm text-[#121212] tracking-[2%]'>Nhân bản</span>
              </div>
            </ButtonAction>
            {renderMoreAction()}
          </div>
        </div>

        <ButtonIcon onClick={funcReset} iconSrc="assets/img/icons/reset.svg" />
      </div >
    )
  };

  const renderFormAction = () => {
    const handleClick = async () => {
      // TODO: Implement logic for form submission
    }

    return (
      <div className='relative'>
        <button
          onClick={() => setOnActions(!isOnActions)}
          className='relative w-6 h-6 flex items-center justify-center'
        >
          <ImageWithBasePath src='assets/img/setting.svg' className='h-4 w-4' alt='setting' width={16} height={16} />
        </button>

        {/* <div className={`${!isOnActions ? "hidden" : 'z-[100] absolute top-[calc(100%+10px)] start-0 '} shadow-[0_12px_16px_-1px_rgba(0,0,0,0.04),0_1px_5px_0_rgba(0,0,0,0.04)] min-w-fit w-[320px] bg-white rounded-2xl p-2 border border-[#E5E5E5] `}>
          <ButtonAction onClick={handleClick} className={'group'}>
            <div className='flex w-full gap-3'>
              <ImageWithBasePath src='assets/img/icons/printer-2.svg' />
              <span className='font-normal text-sm text-[#121212] tracking-[2%]'>Import dữ liệu</span>
            </div>
          </ButtonAction>
        </div> */}
      </div>
    )
  }

  return (
    <>
      <div className='add-item flex items-center gap-[8px] h-full order-1 flex-1'>
        <div className='page-title flex items-center h-full'>
          <div className="icon me-2 md:me-4 p-2 border border-[#E2E4E9] rounded-full flex justify-center items-center bg-primary w-12 h-12">
            <img
              src={existIcon?.url_icon && existIcon?.url_icon?.startsWith("http") ? existIcon.url_icon : process.env.REACT_APP_DOMAIN + existIcon.url_icon}
              width={20}
              height={20}
              alt="img"
            />
          </div>
          <div className={`flex ${isShow ? "gap-6 items-end" : "gap-2 items-center"}`}>
            <div className="group-title">
              <div className='title text-base md:text-xl tracking-wide text-dark font-semibold whitespace-nowrap'>{title?.toUpperCase()}</div>
              <div className='sub-title text-xl tracking-wide font-normal text-primary whitespace-nowrap'>{subTitle}</div>
            </div>
            {isShow ? renderActions() : renderFormAction()}
          </div>
        </div>
      </div>

      <LayerLoading loading={loading} />
    </>
  );
};

export default LeftToolbar;