import { useMutation } from '@tanstack/react-query';
import EmojiPicker from 'emoji-picker-react';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ChatterAction from '../../core/services/chatter';
import { ButtonSelectFiles, RenderSelectFiles } from './SelectFiles';
import { showErrorMessage, showSuccessMessage } from '../../util/util';
import { useTranslation } from 'react-i18next';

const MessageContent = ({ handleAfterSubmit, }) => {
    const [searchParams] = useSearchParams()
    const resModel = searchParams.get('model')
    const id = searchParams.get('id')
    const [text, setText] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const { t } = useTranslation();
    const [selectedFiles, setSelectedFiles] = useState([]);


    const onEmojiClick = (emojiObject) => {
        setText((prevText) => prevText + emojiObject.emoji);
        setShowPicker(false)
        textareaRef.current?.focus();
    };
    const pickerRef = useRef(null);
    const buttonRef = useRef(null);
    const textareaRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                showPicker &&
                pickerRef.current &&
                !pickerRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ) {
                setShowPicker(false);
                // Focus back to textarea
                textareaRef.current?.focus();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [showPicker]);

    const { mutateAsync } = useMutation({
        mutationFn: ({ attachment_ids = [] }) => ChatterAction.sentComment({
            context: {},
            message: text,
            subtype: 'mail.mt_note',
            thread_model: resModel,
            thread_id: id,
            attachment_ids: attachment_ids,
        }),
        onSuccess: (data) => {
            setText('')
            handleAfterSubmit()
            setSelectedFiles([])
            showSuccessMessage(`${t('send_note_success')}`);
        },
        onError: () => {
            showErrorMessage(`${t('end_note_failure')}`);
        }
    });

    const handleSentMessage = async () => {
        if (text?.trim() === '' && selectedFiles?.length === 0) return
        try {
            const attachment_ids = selectedFiles.map(file => file.id)
            await mutateAsync({ attachment_ids: attachment_ids })
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
            handleSentMessage();
        }
    };

    const fileInputRef = useRef(null);

    return (
        <>
            <div className="flex flex-col gap-[8px] w-full">
                <div className="flex gap-[4px] w-full">
                    <div className="pt-[4px]">
                        <div className="w-[36px] h-[36px] rounded-full overflow-hidden">
                            <img src="/assets/img/avatar/admin.jpg" alt="avatar" className="object-cover" />
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px] w-full">
                        <div className="w-full border-[1px] border-solid border-[#dee2e6] rounded-[14px] bg-[#fff]">
                            <textarea
                            placeholder='Gửi tin nhắn đến những người theo dõi'
                                ref={textareaRef}
                                className="bg-transparent outline-none border-none px-[16px] py-[10px] resize-none w-full"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                onKeyDown={handleKeyDown}
                            ></textarea>
                            <div className="border-t-[1px] border-solid border-[#ced4da] flex justify-between items-center mx-[16px] p-[4px]">
                                <div className="flex items-center gap-[16px] relative h-[32px]">
                                    {showPicker && (
                                        <div ref={pickerRef} className="absolute bottom-[16px] right-[-250px] mb-2">
                                            <EmojiPicker
                                                onEmojiClick={onEmojiClick}
                                                autoFocusSearch={false}
                                                searchPlaceholder="Search emojis..."
                                                width={300}
                                                height={390}
                                                type="button"
                                            />
                                        </div>
                                    )}
                                    <button
                                        type='button'
                                        ref={buttonRef}
                                        onClick={() => setShowPicker(!showPicker)}
                                        className="w-[22px] h-[24px] rounded-full overflow-hidden"
                                    >
                                        <img src="/assets/img/chatter/smile.svg" alt="smile" className="object-cover" />
                                    </button>

                                    <ButtonSelectFiles
                                        fileInputRef={fileInputRef}
                                        selectedFiles={selectedFiles}
                                        setSelectedFiles={setSelectedFiles}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center gap-[8px]">
                            <button type='button' onClick={handleSentMessage} className="bg-primary py-[5px] px-[10px] text-[14px] leading-[20px] font-medium rounded-[4px] text-[#fff]">Gửi</button>
                            <div className="font-normal text-[14px] leading-[21px] text-[#333]">CTRL-Enter để gửi</div>
                        </div>

                        {selectedFiles.length > 0 && (
                            <RenderSelectFiles selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
export default MessageContent