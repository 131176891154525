import { create } from 'zustand'

const useTableStore = create((set) => ({
  fields: {},
  selectedRowKeys: [],
  page: 0,
  specification: {},
  footerGroupTable: {},
  indexRowTableModal: -2,
  isUpdateTableModal: false,
  pageLimit: 10,
  setFields: (newFields) => set(() => ({ fields: newFields })),
  setSelectedRowKeys: (newFields) => set(() => ({ selectedRowKeys: newFields })),
  setSpecification: (newSpecification) => set(() => ({ specification: newSpecification })),
  setPage: (newPage) => set(() => ({ page: newPage })),
  setPageLimit: (newLimit) => set(() => ({ pageLimit: newLimit })),
  setFooterGroupTable: (newFooterGroupTable) => set(() => ({ footerGroupTable: newFooterGroupTable })),
  setIndexRowTableModal: (indexRowTableModal) => set(() => ({ indexRowTableModal: indexRowTableModal })),
  setIsUpdateTableModal: (isUpdateTableModal) => set(() => ({ isUpdateTableModal: isUpdateTableModal })),
}))

export default useTableStore
