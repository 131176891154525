import React, { useEffect, useState } from 'react';

const LoadingSmall = () => {
     const [activeIndex, setActiveIndex] = useState(0);
    
      useEffect(() => {
        const interval = setInterval(() => {
          setActiveIndex((prevIndex) => (prevIndex + 1) % 6);
        }, 200);
    
        return () => clearInterval(interval);
      }, []);
  return (
    <div className='my-auto flex-1 h-full flex justify-center items-center'>
      <div className='flex justify-center items-center'>
        <div className='flex justify-center flex-col items-center gap-12'>
          <div className="loading-container-small">
            {[...Array(4)].map((_, index) => (
              <div
                key={index}
                className={`loading-item-small ${index === activeIndex ? 'active' : ''}`}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingSmall
