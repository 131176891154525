import React from 'react'
import { ELearning } from "@education-erp/cms"
import '../../input.css'
import { useSearchParams } from 'react-router-dom'

const Elearning = () => {
    const [searchParams] = useSearchParams()
    const id = searchParams.get("id")
    const token = String(localStorage.getItem("accessToken") ?? "")


    return (
        <div className="page-wrapper" >
            <div className="content">
                <ELearning token={token} id={Number(id)} />
            </div>
        </div>
    )
}

export default Elearning
