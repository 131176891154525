import React from 'react'
import { Resizable } from 'react-resizable'
import 'react-resizable/css/styles.css'

const ResizableHeader = ({ onResize, width, ...props }) => {
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className='react-resizable-handle react-resizable-handle-se !bg-none'
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {props?.islast ? undefined : (
            <div className='flex h-[53px] w-[10px] items-end justify-end pb-[12px] pr-[3px]'>
              <div className='h-[30px] w-[1px] bg-[#b2b2b2]'></div>
            </div>
          )}
        </span>
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...props} style={{ width }} />
    </Resizable>
  )
}

export default ResizableHeader
