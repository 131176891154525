import { useEffect } from "react";
import { LocalStorageService } from "../util/lib/LocalStorage";

const useBrowserSession = (onBrowserClose) => {
  useEffect(() => {
    const initBrowserSession = () => {
      try {
        const hasSession = sessionStorage.getItem("browserSession");
        const stayLoginIn = LocalStorageService.getStayLoginIn();

        if (!hasSession && stayLoginIn === "false") {
          if (typeof onBrowserClose === "function") {
            onBrowserClose();
          }
        }

        sessionStorage.setItem("browserSession", "active");
      } catch (error) {
        console.error("Error initializing browser session:", error);
      }
    };

    initBrowserSession();
  }, [onBrowserClose]);
};

export default useBrowserSession;
